import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'SectionToggle',
  props: {
    initialState: {
      type: Boolean,
      "default": false
    },
    title: {
      type: String,
      "default": ""
    },
    subtitle: {
      type: String,
      "default": ""
    },
    hideText: {
      type: String,
      "default": "Hide"
    },
    showText: {
      type: String,
      "default": "Show"
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn,
      darkActive = _useBaseUtils.darkActive;
    var expand = ref(props.initialState);
    var toggleExpand = function toggleExpand() {
      expand.value = !expand.value;
    };
    var currentActionStr = computed(function () {
      return expand.value ? props.hideText : props.showText;
    });
    return {
      __sfc: true,
      props: props,
      I18nFn: I18nFn,
      darkActive: darkActive,
      expand: expand,
      toggleExpand: toggleExpand,
      currentActionStr: currentActionStr
    };
  }
};