import GenericLoader from "~/components/valuationModels/GenericLoader.vue"

export default {
  __name: 'SingleModelLoader',
  setup(__props) {


return { __sfc: true,GenericLoader }
}

}