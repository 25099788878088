import "core-js/modules/web.timers.js";
import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'ConfirmationDialog',
  props: {
    remove: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn,
      darkActive = _useBaseUtils.darkActive;
    var successMsg = ref(false);
    var loader = ref(false);
    var open = computed(function () {
      return $store.state.valuationModel.dialogs.replaceConfirmationDialog;
    });
    var message = computed(function () {
      if (props.remove) {
        return "This action will remove the model from the Hub Summary";
      }
      return "This action will replace an older version of the same model in your hub summary";
    });
    var onClose = function onClose() {
      var state = $store.state.valuationModel.switchToggleActiveModel;
      $store.dispatch("valuationModel/toggleSwitchActiveModel", state);
      $store.dispatch("valuationModel/changeDialogState", {
        dialogId: "replaceConfirmationDialog",
        value: false
      }); // fetch the new state
      $store.dispatch("valuationModel/fetchAllModels");
    };
    var onProceed = function onProceed() {
      loader.value = true;
      $store.dispatch("valuationModel/changeActiveModel", {
        onAfterSuccessfulChange: function onAfterSuccessfulChange() {
          loader.value = false;
          successMsg.value = true;
          setTimeout(function () {
            successMsg.value = false;
          }, 4000);
          onClose();
        }
      });
    };
    return {
      __sfc: true,
      props: props,
      $store: $store,
      I18nFn: I18nFn,
      darkActive: darkActive,
      successMsg: successMsg,
      loader: loader,
      open: open,
      message: message,
      onClose: onClose,
      onProceed: onProceed
    };
  }
};