import UnauthorizedIcon from "~/components/UnauthorizedIcon.vue"
import useBaseUtils from "~/functions/useBaseUtils"


export default {
  __name: 'PeDeltaEstimates',
  props: {
  displayedDateArr: { type: Array, default: () => [] },
  tableData: { type: Array, default: () => [] },
  formatter: { type: Function, default: (value) => value },
  freezeFirstColumn: { type: Boolean, default: false },
},
  setup(__props) {



const { I18nFn } = useBaseUtils()

const getRealizedDate = (dateObj) => {
  const suffix = dateObj?.isEstimate ? "E" : "A"
  return `${dateObj?.fiscalDate}${suffix}`
}

return { __sfc: true,I18nFn, getRealizedDate, UnauthorizedIcon }
}

}