export default {
  __name: 'StyledButton',
  emits: ["click"],
  setup(__props, { emit }) {



return { __sfc: true,emit }
}

}