import { nextTick } from "vue";
import { useEventListener } from "@vueuse/core";
import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'PeNotes',
  props: {
    previewMode: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props) {
    var _window;
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode,
      customGroup = _useBaseUtils.customGroup;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store,
      $vuetify = _useNuxtApp.$vuetify;
    var placeholderText = "Share your thoughts about the company here...";
    var noteTxtRef = ref($store.state.valuationModel.metadata.note);
    var noteContentStickyRef = ref(null);
    var isFixedContainerVisible = ref(false);
    var isFixedContainerOpen = ref(false);
    var isNoteExpanded = ref(false);
    var viewportHeight = ref(null);
    var handleResize = function handleResize() {
      var vv = window.visualViewport;
      viewportHeight.value = vv ? vv.height : window.innerHeight - 100;
    };
    useEventListener((_window = window) === null || _window === void 0 ? void 0 : _window.visualViewport, "resize", handleResize);
    onMounted(function () {
      handleResize();
    });
    var handleExpand = function handleExpand() {
      isFixedContainerVisible.value = true;
      isFixedContainerOpen.value = true;
      nextTick(function () {
        noteContentStickyRef.value.focus();
      });
    };
    var handleExpandNote = function handleExpandNote() {
      isNoteExpanded.value = !isNoteExpanded.value;
      isFixedContainerOpen.value = true;
    };
    var handleMobileExpand = function handleMobileExpand() {
      if ($vuetify.breakpoint.xsOnly) {
        if (!isFixedContainerOpen.value) {
          handleExpand();
        } else {
          isFixedContainerOpen.value = false;
        }
      }
    };
    var onClose = function onClose() {
      isFixedContainerVisible.value = false;
      isNoteExpanded.value = false;
    };
    var handleMinimize = function handleMinimize(e) {
      e.stopPropagation();
      isFixedContainerOpen.value = !isFixedContainerOpen.value;
    };
    var handleFocusOut = function handleFocusOut() {
      if (noteTxtRef.value !== $store.state.valuationModel.metadata.note) {
        $store.dispatch("valuationModel/updateNote", {
          note: noteTxtRef.value,
          userTier: customGroup.value,
          refCode: refCode.value
        });
      }
    };
    var customStickyStyle = computed(function () {
      return {
        "--top": viewportHeight.value ? "".concat(viewportHeight.value, "px") : ""
      };
    });
    return {
      __sfc: true,
      refCode: refCode,
      customGroup: customGroup,
      $store: $store,
      $vuetify: $vuetify,
      placeholderText: placeholderText,
      noteTxtRef: noteTxtRef,
      noteContentStickyRef: noteContentStickyRef,
      isFixedContainerVisible: isFixedContainerVisible,
      isFixedContainerOpen: isFixedContainerOpen,
      isNoteExpanded: isNoteExpanded,
      viewportHeight: viewportHeight,
      handleResize: handleResize,
      handleExpand: handleExpand,
      handleExpandNote: handleExpandNote,
      handleMobileExpand: handleMobileExpand,
      onClose: onClose,
      handleMinimize: handleMinimize,
      handleFocusOut: handleFocusOut,
      customStickyStyle: customStickyStyle
    };
  }
};