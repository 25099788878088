import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'SaveChangesDialog',
  emits: ["close"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var open = ref(true);
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn,
      darkActive = _useBaseUtils.darkActive,
      refCode = _useBaseUtils.refCode,
      customGroup = _useBaseUtils.customGroup;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var onClose = function onClose() {
      open.value = false;
      $store.dispatch("valuationModel/setUserLeaving", false);
      emit("close");
    };
    var modelTitle = I18nFn("Save your Changes?");
    var description = I18nFn("You currently don't have auto-save selected, so if you navigate away all changes made will not be saved.");
    var leaveText = I18nFn("Leave without saving");
    var saveText = I18nFn("Save Changes");
    var onSave = function onSave() {
      $store.dispatch("valuationModel/saveOrEditModel", {
        userTier: customGroup.value,
        refCode: refCode.value
      });
      onClose();
    };
    return {
      __sfc: true,
      open: open,
      emit: emit,
      I18nFn: I18nFn,
      darkActive: darkActive,
      refCode: refCode,
      customGroup: customGroup,
      $store: $store,
      onClose: onClose,
      modelTitle: modelTitle,
      description: description,
      leaveText: leaveText,
      saveText: saveText,
      onSave: onSave
    };
  }
};