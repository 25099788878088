import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import { watch } from "vue";
import { isString } from "lodash";
import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'NumericInput',
  props: {
    calendarYear: {
      type: Number,
      "default": null
    },
    suffix: {
      type: String,
      "default": ""
    },
    allowNegativeNumbers: {
      type: Boolean,
      "default": true
    },
    disabled: {
      type: Boolean,
      "default": false
    },
    metricName: {
      type: String,
      "default": ""
    },
    multiplyBy: {
      type: Number,
      "default": 1
    }
  },
  emits: ["valueChange"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var toTwoDigitFloat = function toTwoDigitFloat(number) {
      var _numberAsStr$split$;
      if (number === 0) {
        return 0;
      }
      if (!number) {
        return;
      }
      var numberAsStr = String(number);
      if (((_numberAsStr$split$ = numberAsStr.split(".")[1]) === null || _numberAsStr$split$ === void 0 ? void 0 : _numberAsStr$split$.length) < 2) {
        return numberAsStr;
      }
      if (isString(number)) {
        return Number(number).toFixed(2);
      }
      return Number(number.toFixed(2));
    };
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var focused = ref(false);
    var currentAssumptionCaseIdx = computed(function () {
      var _$store$state$valuati;
      return (_$store$state$valuati = $store.state.valuationModel) === null || _$store$state$valuati === void 0 || (_$store$state$valuati = _$store$state$valuati.data) === null || _$store$state$valuati === void 0 || (_$store$state$valuati = _$store$state$valuati.filters) === null || _$store$state$valuati === void 0 ? void 0 : _$store$state$valuati.assumptionCase;
    });
    var valueFromStore = computed(function () {
      var _$store$state$valuati2, _inputs$idx;
      var inputs = (_$store$state$valuati2 = $store.state.valuationModel) === null || _$store$state$valuati2 === void 0 || (_$store$state$valuati2 = _$store$state$valuati2.data) === null || _$store$state$valuati2 === void 0 ? void 0 : _$store$state$valuati2.userInputsDataArray;
      var idx = currentAssumptionCaseIdx.value;
      var yearKey = "".concat(props.calendarYear, "##FY");
      var metricObj = (_inputs$idx = inputs[idx]) === null || _inputs$idx === void 0 || (_inputs$idx = _inputs$idx[props.metricName]) === null || _inputs$idx === void 0 ? void 0 : _inputs$idx[yearKey];
      return metricObj;
    });
    var scaledValue = computed(function () {
      return props.multiplyBy * valueFromStore.value;
    });
    var roundUpto = function roundUpto(number, upTo) {
      return Number(number.toFixed(upTo));
    };
    var maxTwoDigitsValueFromStore = computed(function () {
      return roundUpto(scaledValue.value, 2);
    });
    var inputFinalValue = ref(toTwoDigitFloat(scaledValue.value));
    watch(inputFinalValue, function (newVal) {
      emit("valueChange", newVal, props.calendarYear);
    });
    var handleChange = function handleChange(e) {
      var value = e.target.value;
      var splitByCommas = value.split(",");
      var isNegativeValueAndNotAllowed = !props.allowNegativeNumbers && value < 0;
      if (isNegativeValueAndNotAllowed) {
        e.target.value = 0;
      } else if (splitByCommas.length > 1) {
        var splittedFinalVal = Number(splitByCommas[0]);
        inputFinalValue.value = splittedFinalVal;
      } else {
        inputFinalValue.value = Number(value);
      }
    };
    var handleKeyPress = function handleKeyPress(event) {
      var value = event.target.value;
      if (!focused.value && value) {
        event.target.value = "";
        focused.value = true;
      }
    };
    var handleFocusOut = function handleFocusOut() {
      focused.value = false;
    };
    return {
      __sfc: true,
      props: props,
      toTwoDigitFloat: toTwoDigitFloat,
      darkActive: darkActive,
      $store: $store,
      emit: emit,
      focused: focused,
      currentAssumptionCaseIdx: currentAssumptionCaseIdx,
      valueFromStore: valueFromStore,
      scaledValue: scaledValue,
      roundUpto: roundUpto,
      maxTwoDigitsValueFromStore: maxTwoDigitsValueFromStore,
      inputFinalValue: inputFinalValue,
      handleChange: handleChange,
      handleKeyPress: handleKeyPress,
      handleFocusOut: handleFocusOut
    };
  }
};