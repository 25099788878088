var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"section rounded-lg pa-4 flex-item notes"},[_c('div',{staticClass:"d-flex mb-2"},[_c('span',{staticClass:"note-title font-weight-bold"},[_vm._v("Notes")]),_vm._v(" "),_c('v-icon',{staticClass:"mr-0 ml-auto expand-btn",attrs:{"small":"","disabled":_vm.previewMode},on:{"click":_setup.handleExpand}},[_vm._v("\n      mdi-arrow-expand-all\n    ")])],1),_vm._v(" "),_c('v-textarea',{staticClass:"note-desc-field w-full mt-0 pt-0",attrs:{"auto-grow":"","rows":"1","placeholder":_setup.placeholderText,"disabled":_vm.previewMode},on:{"focusout":_setup.handleFocusOut},model:{value:(_setup.noteTxtRef),callback:function ($$v) {_setup.noteTxtRef=$$v},expression:"noteTxtRef"}}),_vm._v(" "),_c('div',{staticClass:"sticky-note-backdrop",class:{ active: _setup.isNoteExpanded }},[_c('div',{staticClass:"sticky-note elevation-4",class:{
        open: _setup.isFixedContainerOpen,
        visible: _setup.isFixedContainerVisible,
        centered: _setup.isNoteExpanded,
      },style:(_setup.customStickyStyle)},[_c('div',{staticClass:"sticky-note-header pa-5 d-flex",class:{
          'pb-0': _setup.isFixedContainerOpen,
          'pb-4': !_setup.isFixedContainerOpen,
        },on:{"click":_setup.handleMobileExpand}},[_c('span',[_vm._v("Notes")]),_vm._v(" "),_c('v-icon',{staticClass:"ml-2 expand-btn d-none d-sm-flex",attrs:{"small":""},on:{"click":_setup.handleExpandNote}},[_vm._v("\n          "+_vm._s(_setup.isNoteExpanded ? "mdi-arrow-collapse" : "mdi-arrow-expand-all")+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"mr-0 ml-auto"},[_c('v-icon',{staticClass:"material-icons-outlined",class:{ 'd-none': _setup.isNoteExpanded },on:{"click":_setup.handleMinimize}},[_vm._v("\n            "+_vm._s(`keyboard_double_arrow_${_setup.isFixedContainerOpen ? "down" : "up"}`)+"\n          ")]),_vm._v(" "),_c('v-icon',{on:{"click":_setup.onClose}},[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c('div',{staticClass:"sticky-note-content px-5 pb-2",class:{
          'd-block': _setup.isFixedContainerOpen,
          'd-none': !_setup.isFixedContainerOpen,
        }},[_c('v-textarea',{ref:"noteContentStickyRef",staticClass:"mt-1 mb-0 sticky-textarea",class:{
            expanded: _setup.isNoteExpanded,
          },attrs:{"placeholder":_setup.placeholderText},on:{"focusout":_setup.handleFocusOut},model:{value:(_setup.noteTxtRef),callback:function ($$v) {_setup.noteTxtRef=$$v},expression:"noteTxtRef"}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }