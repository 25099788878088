var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-dialog',{attrs:{"width":"464","overlay-color":_setup.darkActive ? 'rgb(38, 38, 38)' : 'rgb(50, 50, 50)',"overlay-opacity":_setup.darkActive ? 0.85 : 0.5},on:{"input":_setup.onClose},model:{value:(_setup.open),callback:function ($$v) {_setup.open=$$v},expression:"open"}},[_c('v-card',{staticClass:"dialog-card rounded-lg"},[_c('div',{staticClass:"d-flex pt-3 pr-3"},[_c('div',{staticClass:"mr-0 ml-auto"},[_c('v-icon',{on:{"click":_setup.onClose}},[_vm._v("mdi-close")])],1)]),_vm._v(" "),_c('v-card-title',{staticClass:"px-8"},[_c('h4',{staticClass:"title"},[_vm._v("\n          "+_vm._s(_setup.I18nFn(
              "Would you like to mark this model as 'active' in your summary?"
            ))+"\n        ")])]),_vm._v(" "),_c('v-card-text',{staticClass:"content mb-0 px-8 pb-6"},[_c('p',{staticClass:"mb-0"},[_vm._v("\n          "+_vm._s(_setup.I18nFn(
              "This action will replace an older version in your model hub summary."
            ))+"\n        ")])]),_vm._v(" "),_c('v-card-actions',{staticClass:"pb-8 px-8 actions"},[_c('v-btn',{staticClass:"px-6",attrs:{"block":"","elevation":"0","color":"gray","disabled":_setup.updating},on:{"click":_setup.onClose}},[_vm._v("\n          "+_vm._s(_setup.I18nFn("Keep my old active model"))+"\n        ")]),_vm._v(" "),_c('v-btn',{staticClass:"dark px-6",class:{
            grey: !_setup.darkActive,
            'darken-4': !_setup.darkActive,
            'white--text': !_setup.darkActive,
            'black--text': _setup.darkActive,
            primaryAction: _setup.darkActive,
          },attrs:{"block":"","elevation":"0","text":"","disabled":_setup.updating},on:{"click":_setup.onChangeActiveModel}},[_vm._v("\n          "+_vm._s(_setup.I18nFn("Update my active model"))+"\n          "),(_setup.updating)?_c('v-progress-circular',{staticClass:"ml-2",attrs:{"size":15,"width":2,"color":_setup.darkActive ? 'primaryAction' : 'white',"indeterminate":""}}):_vm._e()],1)],1)],1)],1),_vm._v(" "),_c('v-snackbar',{attrs:{"color":"success"},model:{value:(_setup.notification),callback:function ($$v) {_setup.notification=$$v},expression:"notification"}},[_vm._v("\n    "+_vm._s(_setup.I18nFn("Model added to hub summary"))+"\n  ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }