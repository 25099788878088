import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import PeNotes from "~/components/valuationModels/Pe/PeNotes.vue";
import useBaseUtils from "~/functions/useBaseUtils";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
import { fetchLogo, getLogo, isValidLogo } from "~/utils/tools/logos";
export default {
  __name: 'PeHeader',
  props: {
    tickerInfo: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    stockPrices: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    outdated: {
      type: Boolean,
      "default": false
    },
    formatter: {
      type: Function,
      "default": function _default(v) {
        return v;
      }
    },
    previewMode: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props) {
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn,
      refCode = _useBaseUtils.refCode,
      customGroup = _useBaseUtils.customGroup,
      darkActive = _useBaseUtils.darkActive;
    var _useNuxtApp = useNuxtApp(),
      $axios = _useNuxtApp.$axios,
      $Amplify = _useNuxtApp.$Amplify,
      $store = _useNuxtApp.$store;
    var _useDateTimeFormatter = useDateTimeFormatters(),
      utcEpochToShortDate = _useDateTimeFormatter.utcEpochToShortDate,
      parseUtcDateTime = _useDateTimeFormatter.parseUtcDateTime;
    var $router = useRouter();
    var $route = useRoute();
    var loading = ref(false);
    var cid = computed(function () {
      return $store.state.valuationModel.metadata.companyid;
    });
    var logoExistsInStore = computed(function () {
      var stringCid = String(cid.value);
      return getLogo(stringCid);
    });
    onMounted(function () {
      if (!logoExistsInStore.value) {
        fetchLogo(cid.value, $axios, $Amplify);
      }
    });
    var inputRef = ref(null);
    var inputValue = ref($store.state.valuationModel.metadata.title);
    var metrics = computed(function () {
      return $store.state.valuationModel.templates.headerMetrics;
    });
    var currentModelMetadata = computed(function () {
      return $store.state.valuationModel.metadata;
    });
    var goToNewModel = function goToNewModel() {
      $router.push({
        path: "/stock/model",
        query: {
          cid: $store.state.valuationModel.metadata.companyid,
          tid: $store.state.valuationModel.metadata.tradingitemid,
          ref: refCode.value
        }
      });
    };
    var handleTitleFocusOut = function handleTitleFocusOut() {
      if (inputValue.value !== $store.state.valuationModel.metadata.title) {
        $store.dispatch("valuationModel/updateTitle", {
          title: inputValue.value,
          userTier: customGroup.value,
          refCode: refCode.value
        });
      }
    };
    var createdAtDate = computed(function () {
      var parsed = parseUtcDateTime($store.state.valuationModel.createdAt);
      return utcEpochToShortDate.value.format(parsed);
    });
    var lastEditedDate = computed(function () {
      var parsed = parseUtcDateTime($store.state.valuationModel.lastModified);
      return utcEpochToShortDate.value.format(parsed);
    });
    var companyModelList = computed(function () {
      var _$store$state$valuati;
      var cid = $route.query.cid;
      var currentModelList = (_$store$state$valuati = $store.state.valuationModel.allModels) === null || _$store$state$valuati === void 0 ? void 0 : _$store$state$valuati[cid];
      if (!cid) {
        return [];
      }
      var array = currentModelList || [];
      return array;
    });
    var activeModel = computed(function () {
      var _companyModelList$val, _companyModelList$val2;
      if (!((_companyModelList$val = companyModelList.value) !== null && _companyModelList$val !== void 0 && _companyModelList$val.length)) {
        return false;
      }
      return ((_companyModelList$val2 = companyModelList.value) === null || _companyModelList$val2 === void 0 ? void 0 : _companyModelList$val2.find(function (model) {
        return model.isActive;
      })) || false;
    });
    var isActiveModel = computed(function () {
      var _activeModel$value;
      var modelId = $route.query.modelId;
      var activeModelId = (_activeModel$value = activeModel.value) === null || _activeModel$value === void 0 ? void 0 : _activeModel$value.modelId;
      return modelId && activeModelId && modelId === activeModelId;
    });
    var handleSwitchClick = function handleSwitchClick() {
      var _activeModel$value2;
      if ((_activeModel$value2 = activeModel.value) !== null && _activeModel$value2 !== void 0 && _activeModel$value2.modelId) {
        $store.dispatch("valuationModel/changeDialogState", {
          dialogId: "replaceConfirmationDialog",
          value: true
        });
        return;
      }
      loading.value = true;
      $store.dispatch("valuationModel/changeActiveModel", {
        onAfterSuccessfulChange: function onAfterSuccessfulChange() {
          loading.value = false;
          $store.dispatch("valuationModel/fetchAllModels");
        }
      });
    };
    return {
      __sfc: true,
      I18nFn: I18nFn,
      refCode: refCode,
      customGroup: customGroup,
      darkActive: darkActive,
      $axios: $axios,
      $Amplify: $Amplify,
      $store: $store,
      utcEpochToShortDate: utcEpochToShortDate,
      parseUtcDateTime: parseUtcDateTime,
      $router: $router,
      $route: $route,
      loading: loading,
      cid: cid,
      logoExistsInStore: logoExistsInStore,
      inputRef: inputRef,
      inputValue: inputValue,
      metrics: metrics,
      currentModelMetadata: currentModelMetadata,
      goToNewModel: goToNewModel,
      handleTitleFocusOut: handleTitleFocusOut,
      createdAtDate: createdAtDate,
      lastEditedDate: lastEditedDate,
      companyModelList: companyModelList,
      activeModel: activeModel,
      isActiveModel: isActiveModel,
      handleSwitchClick: handleSwitchClick,
      PeNotes: PeNotes,
      getLogo: getLogo,
      isValidLogo: isValidLogo
    };
  }
};