var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.StyledDialog,{attrs:{"visible":_setup.open,"title":"Create Your Model","max-width":"500"},on:{"update:visible":_setup.handleUpdateVisible}},[(_setup.isAdvancedType)?[_c('label',{attrs:{"for":"modelType"}},[_c('div',{staticClass:"label-name mb-1"},[_vm._v(_vm._s(_setup.I18nFn("Type"))+":")]),_vm._v(" "),_c('v-select',{staticClass:"mb-5",attrs:{"id":"modelType","items":_setup.modelTypes,"outlined":"","dense":"","item-value":"value","item-text":"text","hide-details":""},model:{value:(_setup.selectedModelType),callback:function ($$v) {_setup.selectedModelType=$$v},expression:"selectedModelType"}})],1),_vm._v(" "),_c('label',{staticClass:"label-name"},[_vm._v("\n      "+_vm._s(_setup.I18nFn("Start with Advanced or Guided Version?"))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"mt-3 mb-6"},[_c('ul',{staticClass:"version-toggle"},[_c('li',{class:{
            selected: _setup.version === 'advanced',
          },attrs:{"title":_setup.I18nFn('Advanced')},on:{"click":() => _setup.handleClickType('advanced')}},[_vm._v("\n          "+_vm._s(_setup.I18nFn("Advanced"))+"\n        ")]),_vm._v(" "),_c('li',{class:{
            selected: _setup.version === 'simple',
            disabled: _vm.disableSimpleModel,
          },attrs:{"title":_setup.I18nFn(_setup.simpleModelDisabledTxt)},on:{"click":() => _setup.handleClickType('simple')}},[_vm._v("\n          "+_vm._s(_setup.I18nFn("Guided"))+"\n        ")])]),_vm._v(" "),(_vm.disableSimpleModel)?_c('div',{staticClass:"create-modal-warning d-flex rounded-lg py-3 px-3 mt-2 mb-2"},[_c('v-icon',{attrs:{"color":_setup.darkActive ? '#FFF' : '#333',"small":""}},[_vm._v("\n          mdi-information-outline\n        ")]),_vm._v(" "),_c('div',{staticClass:"ml-2"},[_vm._v("\n          "+_vm._s(_setup.I18nFn(
              "Guided valuation model is not available for this company since the data is not available"
            ))+"\n        ")])],1):_vm._e()])]:_vm._e(),_vm._v(" "),_c('label',{staticClass:"label-name",attrs:{"for":"title-model"}},[_vm._v("\n    "+_vm._s(_setup.I18nFn("Name"))+"\n  ")]),_vm._v(" "),_c('v-text-field',{ref:"titleInput",staticClass:"mt-1 title",attrs:{"id":`title-model-${_setup.props.cid}`,"type":"text","placeholder":_setup.I18nFn('Add a name to your model'),"height":"45","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _setup.onCreate.apply(null, arguments)}},model:{value:(_setup.titleRef),callback:function ($$v) {_setup.titleRef=$$v},expression:"titleRef"}}),_vm._v(" "),_c('div',{staticClass:"footer-wrapper text-right"},[_c('v-btn',{staticClass:"btn-create elevation-0",class:{
        'white--text': !_setup.darkActive,
        'black--text': _setup.darkActive,
      },attrs:{"color":_setup.darkActive ? 'primaryAction' : 'black'},on:{"click":_setup.onCreate}},[_vm._v("\n      "+_vm._s(_setup.I18nFn("Create"))+"\n    ")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }