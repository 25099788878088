import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
import { watch, nextTick } from "vue";
import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'PeFilter',
  props: {
    currencyList: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    filters: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    allDates: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    assumptionCases: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    filterActions: {
      type: Object,
      "default": function _default() {}
    }
  },
  emits: ["rangeChange"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn;
    var makeDateLabels = function makeDateLabels(date, idx) {
      var _date$fiscalDate;
      if (idx % 2 === 0 || !(date !== null && date !== void 0 && date.fiscalDate)) {
        return "";
      }
      return date === null || date === void 0 || (_date$fiscalDate = date.fiscalDate) === null || _date$fiscalDate === void 0 ? void 0 : _date$fiscalDate.split("/")[2];
    };
    var mappedTickLabels = computed(function () {
      return props.allDates.map(makeDateLabels);
    });
    var handleSliderTicks = function handleSliderTicks() {
      var _rangeSliderRef$value;
      var ticks = ((_rangeSliderRef$value = rangeSliderRef.value) === null || _rangeSliderRef$value === void 0 ? void 0 : _rangeSliderRef$value.$el.querySelectorAll(".v-slider__tick")) || [];
      ticks.forEach(function (tick, index) {
        var isTickInRange = index >= props.filters.dateRange[0] && index <= props.filters.dateRange[1];
        if (isTickInRange) {
          tick.style.background = "#ff9900";
        } else {
          tick.style.removeProperty("background");
        }
      });
    };
    var rangeSliderRef = ref(null);
    watch(rangeSliderRef, function () {
      handleSliderTicks();
    }, {
      immediate: true
    });
    var handleSlideChange = function handleSlideChange(currentValues) {
      var _currentValues = _slicedToArray(currentValues, 2),
        firstCurrentDate = _currentValues[0],
        secondCurrentDate = _currentValues[1];
      var _props$filters$dateRa = _slicedToArray(props.filters.dateRange, 2),
        firstRangeDate = _props$filters$dateRa[0],
        secondRangeDate = _props$filters$dateRa[1];
      var isSameValues = firstCurrentDate === firstRangeDate && secondCurrentDate === secondRangeDate;
      if (!isSameValues) {
        emit("rangeChange", currentValues);
        props.filterActions.changeSlider(currentValues);
        nextTick(function () {
          return handleSliderTicks();
        });
      }
    };
    var handleAssumptionCase = function handleAssumptionCase(value) {
      props.filterActions.changeAssumptionCase(value);
    };
    var handleCurrencyChange = function handleCurrencyChange(idx) {
      props.filterActions.changeCurrency(idx);
    };
    var handleChangeUnit = function handleChangeUnit(unit) {
      props.filterActions.changeUnit(unit);
    };
    var handleReset = function handleReset() {
      props.filterActions.resetFilter();
      nextTick(function () {
        return handleSliderTicks();
      });
    };
    var makeThumbLabel = function makeThumbLabel(label) {
      var year = (label === null || label === void 0 ? void 0 : label.split("/")[2]) || "vishi";
      return "'".concat(year);
    };
    return {
      __sfc: true,
      props: props,
      emit: emit,
      I18nFn: I18nFn,
      makeDateLabels: makeDateLabels,
      mappedTickLabels: mappedTickLabels,
      handleSliderTicks: handleSliderTicks,
      rangeSliderRef: rangeSliderRef,
      handleSlideChange: handleSlideChange,
      handleAssumptionCase: handleAssumptionCase,
      handleCurrencyChange: handleCurrencyChange,
      handleChangeUnit: handleChangeUnit,
      handleReset: handleReset,
      makeThumbLabel: makeThumbLabel
    };
  }
};