import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import last from "lodash/last";
var useCiqCharts = function useCiqCharts(props) {
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var items = computed(function () {
    try {
      return props.metrics.map(function (metricObj) {
        var metrics = metricObj.metrics,
          source = metricObj.source;
        return getMetrics(source, metrics);
      });
    } catch (error) {
      console.log({
        error: error
      });
      return props.metrics;
    }
  });
  var storeObjects = computed(function () {
    var _$store$state$ciq, _$store$state$ciq2, _$store$state$ciq3;
    return {
      financials: (_$store$state$ciq = $store.state.ciq) === null || _$store$state$ciq === void 0 || (_$store$state$ciq = _$store$state$ciq.financials) === null || _$store$state$ciq === void 0 || (_$store$state$ciq = _$store$state$ciq.a) === null || _$store$state$ciq === void 0 ? void 0 : _$store$state$ciq.financials,
      estimates: (_$store$state$ciq2 = $store.state.ciq) === null || _$store$state$ciq2 === void 0 || (_$store$state$ciq2 = _$store$state$ciq2.estimates) === null || _$store$state$ciq2 === void 0 || (_$store$state$ciq2 = _$store$state$ciq2.a) === null || _$store$state$ciq2 === void 0 ? void 0 : _$store$state$ciq2.estimates,
      valuation: (_$store$state$ciq3 = $store.state.ciq) === null || _$store$state$ciq3 === void 0 ? void 0 : _$store$state$ciq3.dailyMultiples
    };
  });
  var findMetricInTabsArray = function findMetricInTabsArray(source, metric) {
    var store = storeObjects.value[source];
    var _loop = function _loop() {
        var storeArr = store[i];
        var exists = storeArr.find(function (item) {
          var isSameName = (item === null || item === void 0 ? void 0 : item.name) === (metric === null || metric === void 0 ? void 0 : metric.name);
          if (metric !== null && metric !== void 0 && metric.dataitemid) {
            return isSameName && String(item.dataitemid) === String(metric.dataitemid);
          }
          return isSameName;
        });
        if (exists) {
          var name = exists === null || exists === void 0 ? void 0 : exists.name;
          if (source === "financials") {
            var dateKeys = Object.keys(exists).filter(function (key) {
              return key.endsWith("##FY");
            });
            return {
              v: {
                name: name,
                source: source,
                data: dateKeys.map(function (key) {
                  var obj = exists[key];
                  var timestamp = (obj === null || obj === void 0 ? void 0 : obj.timeVal) || (obj === null || obj === void 0 ? void 0 : obj.dV);
                  var value = obj === null || obj === void 0 ? void 0 : obj.v;
                  return [timestamp, value];
                })
              }
            };
          } // otherwise, its estimates
          var lastActualDateKey = last(Object.keys(exists === null || exists === void 0 ? void 0 : exists.actual));
          var meanKeys = Object.keys(exists === null || exists === void 0 ? void 0 : exists.mean).filter(function (key) {
            return key > lastActualDateKey;
          });
          var mean = meanKeys.reduce(function (acc, key) {
            acc[key] = exists === null || exists === void 0 ? void 0 : exists.mean[key];
            return acc;
          }, {});
          var mergedData = _objectSpread(_objectSpread({}, exists === null || exists === void 0 ? void 0 : exists.actual), mean);
          return {
            v: {
              name: name,
              source: source,
              data: mergedData
            }
          };
        }
      },
      _ret;
    for (var i = 0; i < store.length; i++) {
      _ret = _loop();
      if (_ret) return _ret.v;
    }
  };
  var mapDifferentSources = function mapDifferentSources(item, source) {
    if (source === "valuation") {
      var storeObj = Object.values(storeObjects.value[source]).find(function (val) {
        return (val === null || val === void 0 ? void 0 : val.name) === (item === null || item === void 0 ? void 0 : item.name);
      });
      var type = storeObj === null || storeObj === void 0 ? void 0 : storeObj.type;
      var yAxis = storeObj === null || storeObj === void 0 ? void 0 : storeObj.yAxis;
      return {
        name: item.name,
        source: source,
        data: storeObj === null || storeObj === void 0 ? void 0 : storeObj.data,
        type: type,
        yAxis: yAxis
      };
    }
    return findMetricInTabsArray(source, item);
  };
  var getMetrics = function getMetrics(source, metricsArr) {
    return {
      source: source,
      data: metricsArr.map(function (item) {
        return mapDifferentSources(item, source);
      })
    };
  };
  return {
    items: items
  };
};
export default useCiqCharts;