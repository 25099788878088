import useBaseUtils from "~/functions/useBaseUtils"
import NumericInput from "~/components/inputs/NumericInput.vue"
import UnauthorizedIcon from "~/components/UnauthorizedIcon.vue"
import PeCell from "~/components/valuationModels/Pe/PeCell.vue"
import { findExRate } from "~/utils/ciq"
import { shouldDisplayInput, getRowKey } from "~/utils/constants/valuationModel"


export default {
  __name: 'PeOperatingData',
  props: {
  displayedDateArr: { type: Array, default: () => [] },
  displayedRowArr: { type: Array, default: () => [] },
  tableData: { type: Object, default: () => {} },
  tableRowLabels: { type: Object, default: () => {} },
  userInputRows: { type: Array, default: () => [] },
  currentIsoCurrency: { type: String, default: "USD" },
  desiredUnit: { type: Number, default: 1 },
  formatter: { type: Function, default: (value) => value },
  options: {
    type: Object,
    default: () => ({
      getExRate: findExRate,
      displayInput: shouldDisplayInput,
      getRowKey,
    }),
  },
  financialDataset: { type: Boolean, default: false },
  freezeFirstColumn: { type: Boolean, default: false },
  assumptionCaseIndex: { type: Number, default: 0 },
  previewMode: { type: Boolean, default: false },
},
  emits: ["valueChange"],
  setup(__props, { emit }) {

const props = __props;




const { I18nFn, dev } = useBaseUtils()

const getRealizedAtDateStr = (dateObj) => {
  const label = dateObj?.fiscalDate
  const suffix = dateObj.isEstimate ? "E" : "A"
  return `${label}${suffix}`
}

const handleValueChange = (value, calendarYear, id) => {
  emit("valueChange", value, calendarYear, id)
}

const isFirstHighlightRow = (idx, rowArr) => {
  const firstHighlightIndex = rowArr.indexOf(
    rowArr.find((item) => item.highlight)
  )
  return idx === firstHighlightIndex
}

const findLastHighlightIndex = (array) => {
  const reversedArray = [...array].reverse()
  const reversedIndex = reversedArray.findIndex((obj) => obj.highlight)
  return reversedIndex === -1 ? -1 : array.length - 1 - reversedIndex
}

const isLastHighlightRow = (idx, rowArr) => {
  const lastHighlightIndex = findLastHighlightIndex(rowArr)
  return idx === lastHighlightIndex
}

const generateRowClasses = (row, idx, displayedRowArr) => {
  return {
    "yellow-highlight": row?.highlight,
    "blank-row": row.key === "blank",
    compact: row.spaced,
    first: row?.highlight && isFirstHighlightRow(idx, displayedRowArr),
    last: row?.highlight && isLastHighlightRow(idx, displayedRowArr),
  }
}

const generateLabelClasses = (row) => {
  return {
    // 'font-weight-bold': row.bold,
    "font-italic": row.spaced,
    "text-left": true,
  }
}

const generateCommonCellClasses = (userInputRows, date, row, cellData) => {
  return {
    "red--text":
      !props.options.displayInput(userInputRows, date, row.key) &&
      cellData?.v < 0,
    // 'font-weight-bold': row.bold,
    "has-input": props.options.displayInput(userInputRows, date, row.key),
    "font-italic":
      ["pct", "whole_pct"].includes(row.format) &&
      !props.options.displayInput(userInputRows, date, row.key),
  }
}

const getAllowNegativeProp = (userInputRows, key) => {
  return userInputRows.find((i) => i.metricKey === key)?.allowNegative
}

const log = (data) => {
  if (dev.value) {
    console.log({ data })
  }
}

return { __sfc: true,props, emit, I18nFn, dev, getRealizedAtDateStr, handleValueChange, isFirstHighlightRow, findLastHighlightIndex, isLastHighlightRow, generateRowClasses, generateLabelClasses, generateCommonCellClasses, getAllowNegativeProp, log, NumericInput, UnauthorizedIcon, PeCell, getRowKey }
}

}