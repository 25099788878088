import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'GenericLoader',
  props: {
    wrapperClasses: {
      type: String,
      "default": ""
    }
  },
  setup: function setup(__props) {
    var _useNuxtApp = useNuxtApp(),
      $vuetify = _useNuxtApp.$vuetify;
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn,
      darkActive = _useBaseUtils.darkActive;
    return {
      __sfc: true,
      $vuetify: $vuetify,
      I18nFn: I18nFn,
      darkActive: darkActive
    };
  }
};