import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.number.constructor.js";
export default {
  __name: 'PeCell',
  props: {
    value: {
      type: Number,
      "default": 0
    },
    format: {
      type: String,
      "default": "float"
    },
    unitId: {
      type: Number,
      "default": 2
    },
    desiredUnit: {
      type: Number,
      "default": 1
    },
    // Millions as default
    exRate: {
      type: Number,
      "default": 1
    },
    formatter: {
      type: Function,
      "default": function _default(value) {
        return value;
      }
    },
    cellCurrency: {
      type: String,
      "default": "USD"
    },
    currency: {
      type: String,
      "default": "USD"
    },
    hide: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var scalerFn = function scalerFn(val, unitId) {
      switch (unitId) {
        case 0:
          return val * 1000;
        case 1:
          return val;
        case 2:
          return val / 1000;
        default:
          return val;
      }
    };
    var convertedValue = computed(function () {
      if (props.format !== "currency" || props.cellCurrency === props.currency) {
        return props.value;
      } else {
        return props.value / props.exRate;
      }
    });
    var scaledValue = computed(function () {
      if (["pct"].includes(props.format)) {
        return props.value;
      }
      return scalerFn(convertedValue.value, props.desiredUnit);
    });
    var formattedValue = computed(function () {
      var formatted = props.formatter(scaledValue.value, props.format);
      if (scaledValue.value === 0) {
        return props.formatter(0, props.format);
      }
      return formatted;
    });
    return {
      __sfc: true,
      props: props,
      scalerFn: scalerFn,
      convertedValue: convertedValue,
      scaledValue: scaledValue,
      formattedValue: formattedValue
    };
  }
};