var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"wrapper",staticClass:"styled-menu-wrapper d-inline-block",class:{
    'no-label-menu': !_setup.activeMenuItem?.label && !_vm.activatorProps?.label,
  }},[_c('v-menu',{attrs:{"attach":_setup.wrapper,"right":_vm.right,"offset-y":"","nudge-bottom":"6px","z-index":_vm.zIndex,"content-class":`elevation-0 rounded-lg dropdown-menu ${
      _vm.right ? 'right-aligned' : 'left-aligned'
    }`,"min-width":_vm.dropdownMinWidth},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-lg dropdown-btn",class:_vm.activatorProps?.class,attrs:{"elevation":"0","outlined":"","disabled":_vm.disabled,"icon":!_vm.activatorProps.label && !!_vm.activatorProps.icon}},'v-btn',attrs,false),on),[(_setup.activeMenuItem?.label && _vm.displayActiveMenuLabel)?[_vm._v("\n          "+_vm._s(_setup.activeMenuItem?.label)+"\n        ")]:(_vm.activatorProps.label)?_c('span',[_vm._v("\n          "+_vm._s(_vm.activatorProps.label)+"\n        ")]):_vm._e(),_vm._v(" "),_c('v-icon',{class:{
            active: _setup.toggle,
            rotate: _vm.activatorProps?.rotateIconOnOpen,
            'ml-2': _vm.activatorProps?.label,
          },attrs:{"color":_vm.activatorProps?.iconColor,"small":""}},[_vm._v("\n          "+_vm._s(_vm.activatorProps.icon)+"\n        ")])],2)]}}]),model:{value:(_setup.toggle),callback:function ($$v) {_setup.toggle=$$v},expression:"toggle"}},[_vm._v(" "),_c('v-list',{staticClass:"options-list elevation-0 rounded-lg pa-1"},[(_vm.items.length)?_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,attrs:{"dense":""},on:{"click":function($event){return _setup.changeCurrentItem(item)}}},[_vm._v("\n          "+_vm._s(item.label)+"\n          "),(item.lock && item.value !== _setup.selected)?_c('v-icon',{staticClass:"mr-0 ml-auto",attrs:{"small":""}},[_vm._v("\n            mdi-lock\n          ")]):(
              _vm.displayActiveMenuIcon && _setup.selected && item.value === _setup.selected
            )?[_c('v-icon',{staticClass:"mr-0 ml-auto",attrs:{"small":""}},[_vm._v("mdi-check")])]:_vm._e()],2)}):[_vm._t("default")]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }