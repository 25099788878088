import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import useBaseUtils from "~/functions/useBaseUtils";
import useInitialPeValuationInputs from "~/functions/useInitialPeValuationInputs";
import useNumberFormatters from "~/functions/useNumberFormatters";
import { initialFilterState } from "~/utils/valuationTransform/peValuationTemplates";
import { getEstimatesScaledValue } from "~/utils/values/scale";
var usePeValuationModel = function usePeValuationModel(_ref) {
  var templates = _ref.templates;
  var _useInitialPeValuatio = useInitialPeValuationInputs({
      templates: templates
    }),
    loading = _useInitialPeValuatio.loading,
    allDates = _useInitialPeValuatio.allDates,
    currencies = _useInitialPeValuatio.currencies,
    isFinancialDataSet = _useInitialPeValuatio.isFinancialDataSet,
    isModelDisabled = _useInitialPeValuatio.isModelDisabled,
    initVendorDataObj = _useInitialPeValuatio.initVendorDataObj,
    userInputDataObjs = _useInitialPeValuatio.userInputDataObjs,
    mostRecentActualDateKeyStr = _useInitialPeValuatio.mostRecentActualDateKeyStr,
    valuationOutputDataObject = _useInitialPeValuatio.valuationOutputDataObject,
    hasInvalidEPS = _useInitialPeValuatio.hasInvalidEPS,
    companyWarningMessage = _useInitialPeValuatio.companyWarningMessage,
    stockValues = _useInitialPeValuatio.stockValues,
    filters = _useInitialPeValuatio.filters,
    resetCount = _useInitialPeValuatio.resetCount,
    setDefaultRange = _useInitialPeValuatio.setDefaultRange,
    forecastTable = _useInitialPeValuatio.forecastTable,
    utcEpochToShortDate = _useInitialPeValuatio.utcEpochToShortDate,
    chartData = _useInitialPeValuatio.chartData,
    isValidLastActuals = _useInitialPeValuatio.isValidLastActuals,
    recommendedDataset = _useInitialPeValuatio.recommendedDataset,
    currentDataset = _useInitialPeValuatio.currentDataset,
    actualDataset = _useInitialPeValuatio.actualDataset,
    formatPercent = _useInitialPeValuatio.formatPercent,
    formatNumber = _useInitialPeValuatio.formatNumber,
    formatCurrencyTwoDecimals = _useInitialPeValuatio.formatCurrencyTwoDecimals,
    formatCurrencyReactive = _useInitialPeValuatio.formatCurrencyReactive,
    oneDigitPercentFormatter = _useInitialPeValuatio.oneDigitPercentFormatter,
    rowLabels = _useInitialPeValuatio.rowLabels,
    forecastedSummaryDataArr = _useInitialPeValuatio.forecastedSummaryDataArr,
    historicalYears = _useInitialPeValuatio.historicalYears,
    historicalTableFilteredData = _useInitialPeValuatio.historicalTableFilteredData,
    freshInitVendorDataObj = _useInitialPeValuatio.freshInitVendorDataObj,
    isOutOfDate = _useInitialPeValuatio.isOutOfDate,
    simpleModelProps = _useInitialPeValuatio.simpleModelProps;
  var _useBaseUtils = useBaseUtils(),
    freezeFirstColumn = _useBaseUtils.freezeFirstColumn;
  var _useNumberFormatters = useNumberFormatters(),
    formatTurnsValue = _useNumberFormatters.formatTurnsValue;
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var getScaledValue = function getScaledValue(value, unittypeid) {
    return getEstimatesScaledValue(value, unittypeid, null);
  };
  var getUpdatedObject = function getUpdatedObject(currentUserInputDataObj, metricKey, yearKey, value) {
    var rowMatch = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
    return Object.keys(currentUserInputDataObj).reduce(function (acc, key) {
      var item = currentUserInputDataObj[key];
      acc[key] = {};
      for (var index = 0; index < Object.keys(item).length; index++) {
        var currentYearKey = Object.keys(item)[index];
        acc[key][currentYearKey] = item[currentYearKey];
        if (rowMatch && metricKey === key) {
          acc[key][currentYearKey] = value;
        } else if (metricKey === key && yearKey === currentYearKey) {
          acc[key][currentYearKey] = value;
        }
      }
      return acc;
    }, {});
  };
  var updateSingleInput = function updateSingleInput(metricKeyName, value, year, assumptionCaseIdx) {
    var _templates$userInputD, _currentCaseInputs$me;
    var yearKey = "".concat(year, "##FY");
    var unittypeid = (_templates$userInputD = templates.userInputDataArray.find(function (obj) {
      return obj.metricKey === metricKeyName;
    })) === null || _templates$userInputD === void 0 ? void 0 : _templates$userInputD.unittypeid;
    var scaledValue = getScaledValue(value, unittypeid);
    var currentCaseInputs = userInputDataObjs.value[assumptionCaseIdx];
    var isValid = isFinite(currentCaseInputs === null || currentCaseInputs === void 0 || (_currentCaseInputs$me = currentCaseInputs[metricKeyName]) === null || _currentCaseInputs$me === void 0 ? void 0 : _currentCaseInputs$me[yearKey]);
    if (isValid) {
      var newUpdatedObj = getUpdatedObject(currentCaseInputs, metricKeyName, yearKey, scaledValue || value);
      var newArr = _toConsumableArray(userInputDataObjs.value);
      newArr[assumptionCaseIdx] = newUpdatedObj;
      return _toConsumableArray(newArr);
    }
    return _toConsumableArray(userInputDataObjs.value);
  };
  var updateSingleRow = function updateSingleRow(rowId, value, assumptionCaseIdx) {
    var _templates$userInputD2;
    var unittypeid = (_templates$userInputD2 = templates.userInputDataArray.find(function (obj) {
      return obj.metricKey === rowId;
    })) === null || _templates$userInputD2 === void 0 ? void 0 : _templates$userInputD2.unittypeid;
    var scaledValue = getScaledValue(value, unittypeid);
    var currentCaseInputs = userInputDataObjs.value[assumptionCaseIdx];
    var newUpdatedObject = getUpdatedObject(currentCaseInputs, rowId, null, scaledValue || value, true);
    var newArr = _toConsumableArray(userInputDataObjs.value);
    newArr[assumptionCaseIdx] = newUpdatedObject;
    return _toConsumableArray(newArr);
  };
  var userInputs = computed(function () {
    return templates.userInputDataArray.map(function (item) {
      var _item$allowNegative;
      return {
        metricKey: item.metricKey,
        u: item === null || item === void 0 ? void 0 : item.unittypeid,
        multiplyBy: item === null || item === void 0 ? void 0 : item.multiplyBy,
        allowNegative: (_item$allowNegative = item === null || item === void 0 ? void 0 : item.allowNegative) !== null && _item$allowNegative !== void 0 ? _item$allowNegative : true
      };
    });
  });
  var displayedDateArr = computed(function () {
    var filtered = allDates.value.filter(function (_, idx) {
      var isInRange = idx >= filters.value.dateRange[0] && idx <= filters.value.dateRange[1];
      return isInRange;
    });
    if (filters.value.reverse) {
      return filtered.reverse();
    }
    return filtered;
  });
  var threeYearForecastDateStr = computed(function () {
    if (!mostRecentActualDateKeyStr.value) return;
    var _mostRecentActualDate = mostRecentActualDateKeyStr.value.split("##"),
      _mostRecentActualDate2 = _slicedToArray(_mostRecentActualDate, 1),
      currentYear = _mostRecentActualDate2[0];
    var yearToLookup = (Number(currentYear) + 3).toString();
    var lastDayOfTheYear = new Date(yearToLookup, 11, 31);
    return utcEpochToShortDate.value.format(lastDayOfTheYear);
  });
  var generateDeltaToEstimates = function generateDeltaToEstimates(valOutputDataObj, estimatesMetricsArr, initVendorDataObj, mostRecentActualDateKeyStr) {
    return estimatesMetricsArr.reduce(function (acc, item) {
      var key = item.metricKey;
      var label = item.label;
      var userEstimated = (valOutputDataObj === null || valOutputDataObj === void 0 ? void 0 : valOutputDataObj[key]) || {};
      var consensus = Object.keys(userEstimated).reduce(function (subAcc, subitemKey) {
        var _initVendorDataObj$ke, _initVendorDataObj$ke2, _valOutputDataObj$key;
        var item = (_initVendorDataObj$ke = initVendorDataObj === null || initVendorDataObj === void 0 || (_initVendorDataObj$ke2 = initVendorDataObj[key]) === null || _initVendorDataObj$ke2 === void 0 ? void 0 : _initVendorDataObj$ke2[subitemKey]) !== null && _initVendorDataObj$ke !== void 0 ? _initVendorDataObj$ke : valOutputDataObj === null || valOutputDataObj === void 0 || (_valOutputDataObj$key = valOutputDataObj[key]) === null || _valOutputDataObj$key === void 0 ? void 0 : _valOutputDataObj$key[subitemKey];
        if (subitemKey > mostRecentActualDateKeyStr) {
          subAcc[subitemKey] = item;
        }
        return subAcc;
      }, {});
      var deltaDiff = Object.keys(userEstimated).reduce(function (subAcc, subitemKey) {
        var item = userEstimated[subitemKey];
        var consensusItem = consensus[subitemKey];
        if (isFinite(item === null || item === void 0 ? void 0 : item.v) && isFinite(consensusItem === null || consensusItem === void 0 ? void 0 : consensusItem.v)) {
          var pctChange = ((item === null || item === void 0 ? void 0 : item.v) - (consensusItem === null || consensusItem === void 0 ? void 0 : consensusItem.v)) / (consensusItem === null || consensusItem === void 0 ? void 0 : consensusItem.v);
          var diff = (consensusItem === null || consensusItem === void 0 ? void 0 : consensusItem.v) - (item === null || item === void 0 ? void 0 : item.v);
          var isSmallDiff = Math.abs(diff) < 0.001;
          var v = isSmallDiff ? 0 : pctChange;
          var unauth = (item === null || item === void 0 ? void 0 : item.unauth) || (consensusItem === null || consensusItem === void 0 ? void 0 : consensusItem.unauth);
          subAcc[subitemKey] = _objectSpread(_objectSpread({}, item), {}, {
            unauth: unauth,
            v: v,
            classList: {
              "green--text": v >= 0,
              "red--text": v < 0
            }
          });
        }
        return subAcc;
      }, {});
      var isEveryObjEmpty = Object.keys(consensus).every(function (key) {
        return Object.keys(consensus[key]).length === 0;
      });
      if (!isEveryObjEmpty) {
        acc.push({
          label: "User Estimated ".concat(label),
          key: "ue_".concat(key),
          data: userEstimated
        });
        acc.push({
          label: "Consensus ".concat(label),
          key: "consensus_".concat(key),
          data: consensus,
          showTooltip: true
        });
        acc.push({
          label: "Delta",
          key: "delta_".concat(key),
          format: "pct",
          data: deltaDiff,
          classList: {
            highlight: true,
            "font-weight-bold": true
          }
        });
      }
      return acc;
    }, []);
  };
  var deltaEstimates = computed(function () {
    var _freshInitVendorDataO;
    return generateDeltaToEstimates(valuationOutputDataObject.value, templates.estimatesDataArray, (_freshInitVendorDataO = freshInitVendorDataObj.value) === null || _freshInitVendorDataO === void 0 ? void 0 : _freshInitVendorDataO.data, mostRecentActualDateKeyStr.value);
  });
  var lessThanZeroFormatter = function lessThanZeroFormatter(v, formatter) {
    if (v < 0) {
      return "(".concat(formatter(Math.abs(v)), ")");
    }
    return formatter(v);
  };
  var formatterList = computed(function () {
    return {
      pct: function pct(v) {
        return lessThanZeroFormatter(v, formatPercent.value.format);
      },
      "float": function float(v) {
        return lessThanZeroFormatter(v, formatNumber.value.format);
      },
      currency: function currency(v) {
        return lessThanZeroFormatter(v, formatCurrencyReactive.value.format);
      },
      turns: function turns(v) {
        return formatTurnsValue(v, true);
      },
      oneDigitPct: function oneDigitPct(v) {
        return lessThanZeroFormatter(v, oneDigitPercentFormatter.value.format);
      },
      twoDigitCurrency: function twoDigitCurrency(v) {
        return lessThanZeroFormatter(v, formatCurrencyTwoDecimals.value.format);
      }
    };
  });
  var currencyIsoCode = computed(function () {
    var _currencies$value;
    return (_currencies$value = currencies.value) === null || _currencies$value === void 0 ? void 0 : _currencies$value[filters.value.currency].code;
  });
  var headerProps = computed(function () {
    return {
      "stock-prices": stockValues.value,
      outdated: isOutOfDate.value,
      formatter: function formatter(val, format) {
        return cellFormatterFn(val, format, true);
      }
    };
  });
  var operatingDataProps = computed(function () {
    return {
      "displayed-date-arr": displayedDateArr.value,
      "displayed-row-arr": templates.operatingDataRowArray,
      "table-data": valuationOutputDataObject.value,
      "table-row-labels": rowLabels.value,
      "user-input-rows": userInputs.value,
      "current-iso-currency": currencyIsoCode.value,
      "desired-unit": filters.value.unit,
      "financial-dataset": isFinancialDataSet.value,
      "freeze-first-column": freezeFirstColumn.value,
      "assumption-case-index": filters.value.assumptionCase,
      formatter: function formatter(val, format) {
        return cellFormatterFn(val, format, false);
      }
    };
  });
  var forecastSummaryProps = computed(function () {
    return {
      "displayed-date-arr": forecastedSummaryDataArr.value,
      "displayed-row-arr": templates.summaryDataRowArray,
      "table-data": valuationOutputDataObject.value,
      "table-row-labels": rowLabels.value,
      "user-input-rows": userInputs.value,
      "most-recent-actual-date-key-str": mostRecentActualDateKeyStr.value,
      "current-iso-currency": currencyIsoCode.value,
      "desired-unit": filters.value.unit,
      "freeze-first-column": freezeFirstColumn.value,
      "assumption-case-index": filters.value.assumptionCase,
      formatter: cellFormatterFn
    };
  });
  var overallSummaryProps = computed(function () {
    return _objectSpread({
      "realized-at": threeYearForecastDateStr.value,
      "chart-data": chartData.value,
      "date-array": displayedDateArr.value,
      "date-range": filters.value.dateRange
    }, screenshotProps.value);
  });
  var screenshotProps = computed(function () {
    var _forecastTable$value, _forecastTable$value2;
    return {
      "assumption-cases": templates.assumptionCases,
      "forecast-table-data": (_forecastTable$value = forecastTable.value) === null || _forecastTable$value === void 0 ? void 0 : _forecastTable$value.tableData,
      "forecast-table-date-range": (_forecastTable$value2 = forecastTable.value) === null || _forecastTable$value2 === void 0 ? void 0 : _forecastTable$value2.dateRange,
      "historical-years": historicalYears.value,
      "historical-table-filtered-data": historicalTableFilteredData.value,
      formatter: function formatter(val, format) {
        return cellFormatterFn(val, format, true);
      }
    };
  });
  var filterProps = computed(function () {
    return {
      "currency-list": currencies.value,
      "all-dates": allDates.value,
      "assumption-cases": templates.assumptionCases,
      "filter-actions": {
        changeAssumptionCase: changeAssumptionCase,
        changeCurrency: changeCurrency,
        changeDecimals: changeDecimals,
        changeSlider: changeSlider,
        changeUnit: changeUnit,
        resetFilter: resetFilter,
        reverseRows: reverseRows
      },
      filters: filters.value
    };
  });
  var deltaEstimatesProps = computed(function () {
    return {
      "displayed-date-arr": displayedDateArr.value,
      "table-data": deltaEstimates.value,
      "freeze-first-column": freezeFirstColumn.value,
      formatter: cellFormatterFn
    };
  });
  var cellFormatterFn = function cellFormatterFn(value, format) {
    var useCurrency = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    switch (format) {
      case "oneDigitPct":
        return formatterList.value.oneDigitPct(value);
      case "pct":
        return formatterList.value.pct(value);
      case "turns":
        return formatterList.value.turns(value);
      case "currency":
        {
          if (useCurrency) {
            return formatterList.value.currency(value);
          }
          return formatterList.value["float"](value);
        }
      case "twoDigitCurrency":
        return formatterList.value.twoDigitCurrency(value);
      default:
        return formatterList.value["float"](value);
    }
  };
  var reverseRows = function reverseRows() {
    filters.value.reverse = !filters.value.reverse;
  };
  var changeSlider = function changeSlider(slideRangeArr) {
    filters.value.dateRange = slideRangeArr;
  };
  var logData = function logData() {
    console.log({
      userInputDataObjs: userInputDataObjs.value,
      valuationOutputDataObject: valuationOutputDataObject.value,
      initVendorDataObj: initVendorDataObj.value,
      filters: filters.value
    });
  };
  var resetFilter = function resetFilter() {
    Object.keys(initialFilterState).forEach(function (key) {
      if (key === "assumptionCase") {
        var indexOfDefaultState = templates.assumptionCases.findIndex(function (item) {
          return item.isDefault;
        });
        filters.value[key] = indexOfDefaultState;
      } else {
        filters.value[key] = initialFilterState[key];
      }
    }); // this forces the whole table to refresh
    resetCount.value = resetCount.value + 1;
    setDefaultRange({
      datesArr: allDates.value,
      refToUpdate: filters
    });
    $store.dispatch("valuationModel/resetUserInputs");
  };
  var changeCurrency = function changeCurrency(idx) {
    filters.value.currency = idx;
  };
  var changeDecimals = function changeDecimals(amount) {
    filters.value.decimals = filters.value.decimals + amount;
  };
  var changeAssumptionCase = function changeAssumptionCase(num) {
    filters.value.assumptionCase = num;
    $store.dispatch("valuationModel/updateFilterProp", {
      key: "assumptionCase",
      value: num
    });
  };
  var changeUnit = function changeUnit(unit) {
    filters.value.unit = unit;
  };
  return {
    companyWarningMessage: companyWarningMessage,
    filters: filters,
    loading: loading,
    logData: logData,
    updateSingleInput: updateSingleInput,
    updateSingleRow: updateSingleRow,
    isFinancialDataSet: isFinancialDataSet,
    isModelDisabled: isModelDisabled,
    isOutOfDate: isOutOfDate,
    resetCount: resetCount,
    hasInvalidEPS: hasInvalidEPS,
    isValidLastActuals: isValidLastActuals,
    recommendedDataset: recommendedDataset,
    currentDataset: currentDataset,
    actualDataset: actualDataset,
    headerProps: headerProps,
    operatingDataProps: operatingDataProps,
    forecastSummaryProps: forecastSummaryProps,
    overallSummaryProps: overallSummaryProps,
    filterProps: filterProps,
    deltaEstimatesProps: deltaEstimatesProps,
    screenshotProps: screenshotProps,
    simpleModelProps: simpleModelProps
  };
};
export default usePeValuationModel;