import "core-js/modules/es.number.constructor.js";
import { onMounted } from "vue";
import useBaseUtils from "~/functions/useBaseUtils";
import StyledDialog from "~/components/StyledDialog.vue";
export default {
  __name: 'CreateModelDialog',
  props: {
    cid: {
      type: Number,
      "default": null
    },
    tid: {
      type: Number,
      "default": null
    },
    type: {
      type: String,
      "default": "advanced"
    },
    // "simple" or "advanced"
    disableSimpleModel: {
      type: Boolean,
      "default": false
    }
  },
  emits: ["close"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var titleRef = ref("");
    var open = ref(true);
    var selectedModelType = ref("pe");
    var titleInput = ref();
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive,
      I18nFn = _useBaseUtils.I18nFn,
      refCode = _useBaseUtils.refCode;
    var $router = useRouter();
    var version = ref(props.type);
    onMounted(function () {
      titleInput.value.focus();
    });
    var isAdvancedType = computed(function () {
      return props.type === "advanced";
    });
    var handleClickType = function handleClickType(type) {
      if (props.disableSimpleModel && type === "simple") {
        return;
      }
      version.value = type;
    };
    var onCreate = function onCreate() {
      open.value = false;
      var isAdvancedVersion = version.value === "advanced";
      var path = isAdvancedVersion ? "/stock/model" : "/stock/simple-model";
      var query = {
        cid: props.cid,
        tid: props.tid,
        ref: refCode.value,
        title: titleRef.value
      };
      if (isAdvancedVersion) {
        query.initialSave = true;
      }
      $router.push({
        path: path,
        query: query
      });
    };
    var handleUpdateVisible = function handleUpdateVisible(value) {
      open.value = value;
      if (value === false) {
        emit("close");
      }
    };
    var modelTypes = [{
      text: "PE Valuation",
      value: "pe"
    }];
    var simpleModelDisabledTxt = computed(function () {
      return props.disableSimpleModel ? "You must have at least one revenue estimate to create a guided model" : "Simple Model";
    });
    return {
      __sfc: true,
      props: props,
      titleRef: titleRef,
      open: open,
      selectedModelType: selectedModelType,
      emit: emit,
      titleInput: titleInput,
      darkActive: darkActive,
      I18nFn: I18nFn,
      refCode: refCode,
      $router: $router,
      version: version,
      isAdvancedType: isAdvancedType,
      handleClickType: handleClickType,
      onCreate: onCreate,
      handleUpdateVisible: handleUpdateVisible,
      modelTypes: modelTypes,
      simpleModelDisabledTxt: simpleModelDisabledTxt,
      StyledDialog: StyledDialog
    };
  }
};