import "core-js/modules/es.number.constructor.js";
import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'ConfirmationDialog',
  props: {
    width: {
      type: [String, Number],
      "default": "fit-content"
    },
    open: {
      type: Boolean,
      "default": false
    },
    title: {
      type: String,
      "default": "Are you sure?"
    },
    cancelLabel: {
      type: String,
      "default": "Cancel"
    },
    confirmLabel: {
      type: String,
      "default": "Confirm"
    },
    confirmBtnBgColors: {
      type: Array,
      "default": function _default() {
        return ["#EF4444", "#EF4444"];
      }
    },
    wrapperClass: {
      type: String,
      "default": ""
    },
    disabledBtn: {
      type: Boolean,
      "default": false
    },
    manualHide: {
      type: Boolean,
      "default": false
    },
    showConfirmLoader: {
      type: Boolean,
      "default": false
    },
    maxWidth: {
      type: [Number, String],
      "default": "464px"
    }
  },
  emits: ["confirm", "cancel"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive,
      I18nFn = _useBaseUtils.I18nFn;
    var onConfirm = function onConfirm() {
      if (!props.manualHide) {
        dialog.value = false;
      }
      emit("confirm", dialog);
    };
    var onCancel = function onCancel() {
      dialog.value = false;
      emit("cancel");
    };
    var dialog = ref(props.open);
    var confirmBtnStyle = computed(function () {
      return {
        "--bg-light-mode": props.confirmBtnBgColors[0],
        "--bg-dark-mode": props.confirmBtnBgColors[1]
      };
    });
    var computedWidth = computed(function () {
      return typeof props.width === "number" ? "".concat(props.width, "px") : props.width;
    });
    var maxWidthVal = computed(function () {
      if (typeof props.maxWidth === "number") {
        return "".concat(props.maxWidth, "px");
      }
      return props.maxWidth;
    });
    return {
      __sfc: true,
      props: props,
      emit: emit,
      darkActive: darkActive,
      I18nFn: I18nFn,
      onConfirm: onConfirm,
      onCancel: onCancel,
      dialog: dialog,
      confirmBtnStyle: confirmBtnStyle,
      computedWidth: computedWidth,
      maxWidthVal: maxWidthVal
    };
  }
};