import last from "lodash/last"
import useNumberFormatters from "~/functions/useNumberFormatters"

const { formatValueBasedOnType } = useNumberFormatters()

const formatValue = (
  value,
  format,
  nativeCurrencyFormatter,
  usdCurrencyFormatter,
  useUsdCurrencyFormatter
) => {
  if (format === "currency") {
    return formatValueBasedOnType({
      type: format,
      value,
      nativeCurrencyFormatter,
      useUsdCurrencyFormatter,
      usdCurrencyFormatter,
    })
  }
  return formatValueBasedOnType({
    type: format,
    value,
  })
}

const shouldDisplayInput = (userInputRows, dateObj, key) => {
  return (
    userInputRows.find((item) => item.metricKey === key) && dateObj.isEstimate
  )
}

const getRowKey = (row) => {
  if (row.key === "blank") {
    return `blank_${Math.random()}`
  }
  return row.key
}

const isValidGuidedModel = (store, loading, dev, userTier) => {
  if (loading) {
    return true
  }
  const ciqStore = store.state.ciq
  const mostRecentDateKey = ciqStore.estimates?.a?.mostRecentDateKey
  const estimatesResData = ciqStore.estimates?.a?.resData
  const valuationData = ciqStore?.dailyMultiples
  const revenueAmountOfEstimates =
    estimatesResData?.["revenue act."]?.numEst || {}
  const forecastDateKeys = Object.keys(revenueAmountOfEstimates).filter(
    (key) => {
      return key > mostRecentDateKey
    }
  )
  const amountOfEstimates = userTier.endsWith("-free") ? 2 : 3
  const nextThreeForecastDateKeys = forecastDateKeys.slice(0, amountOfEstimates)
  const isEveryRevenueDateKeyValid = nextThreeForecastDateKeys.every((key) => {
    return Number(revenueAmountOfEstimates[key]?.dataitemvalue) >= 3
  })

  const epsNormalizedObj = estimatesResData?.["eps normalized act."]
  const isEveryEpsNormalizedDateKeyValid = nextThreeForecastDateKeys.every(
    (key) => {
      return Number(epsNormalizedObj?.mean?.[key]?.dataitemvalue) >= 0
    }
  )

  const peMultipleObj = valuationData?.["div-priceclose-eps normalized"]
  const lastPeMultiple = last(peMultipleObj?.data)?.[1]
  const isValidLastPeMultiple = lastPeMultiple >= 0 && lastPeMultiple <= 200

  if (dev) {
    console.log({
      RevenuesAmountOfEstimates: nextThreeForecastDateKeys.map((key) =>
        Number(revenueAmountOfEstimates[key]?.dataitemvalue)
      ),
      EPSNormalized: nextThreeForecastDateKeys.map((key) =>
        Number(epsNormalizedObj?.mean?.[key]?.dataitemvalue)
      ),
      lastPeMultiple,
    })
  }

  return (
    isEveryRevenueDateKeyValid &&
    isEveryEpsNormalizedDateKeyValid &&
    isValidLastPeMultiple
  )
}

export { formatValue, isValidGuidedModel, shouldDisplayInput, getRowKey }
