import UnauthorizedIcon from "~/components/UnauthorizedIcon.vue"
import NumericInput from "~/components/inputs/NumericInput.vue"
import PeCell from "~/components/valuationModels/Pe/PeCell.vue"
import useBaseUtils from "~/functions/useBaseUtils"
import { findExRate } from "~/utils/ciq"
import { getYearKey } from "~/utils/tools"
import { shouldDisplayInput, getRowKey } from "~/utils/constants/valuationModel"


export default {
  __name: 'PeForecastedSummary',
  props: {
  displayedDateArr: { type: Array, default: () => [] },
  displayedRowArr: { type: Array, default: () => [] },
  tableData: { type: Object, default: () => {} },
  tableRowLabels: { type: Object, default: () => {} },
  userInputRows: { type: Array, default: () => [] },
  currentIsoCurrency: { type: String, default: "USD" },
  formatter: { type: Function, default: (value) => value },
  desiredUnit: { type: Number, default: 1 },
  mostRecentActualDateKeyStr: { type: String, default: "" },
  options: {
    type: Object,
    default: () => ({
      getExRate: findExRate,
      displayInput: shouldDisplayInput,
      getRowKey,
    }),
  },
  freezeFirstColumn: { type: Boolean, default: false },
  assumptionCaseIndex: { type: Number, default: 0 },
  previewMode: { type: Boolean, default: false },
},
  emits: ["valueChange"],
  setup(__props, { emit }) {

const props = __props;




const { I18nFn } = useBaseUtils()
const handleValueChange = (value, calendarYear, id) => {
  emit("valueChange", value, calendarYear, id)
}

const isFirstHighlightRow = (idx, rowArr) => {
  const firstHighlightIndex = rowArr.indexOf(
    rowArr.find((item) => item.highlight)
  )
  return idx === firstHighlightIndex
}

const findLastHighlightIndex = (array) => {
  const reversedArray = [...array].reverse()
  const reversedIndex = reversedArray.findIndex((obj) => obj.highlight)
  return reversedIndex === -1 ? -1 : array.length - 1 - reversedIndex
}

const isLastHighlightRow = (idx, rowArr) => {
  const lastHighlightIndex = findLastHighlightIndex(rowArr)
  return idx === lastHighlightIndex
}

const generateRowClasses = (row, idx, displayedRowArr) => {
  return {
    "yellow-highlight": row?.highlight,
    first: row?.highlight && isFirstHighlightRow(idx, displayedRowArr),
    last: row?.highlight && isLastHighlightRow(idx, displayedRowArr),
  }
}

const generateTitleCellClasses = (row) => {
  return {
    "font-weight-bold": row.bold,
    "text-left": true,
  }
}

const generateCommonCellClasses = (userInputRows, date, row, cellObj) => {
  return {
    "red--text":
      !props.options.displayInput(userInputRows, date, row.key) &&
      cellObj?.v < 0,
    "font-weight-bold":
      !props.options.displayInput(userInputRows, date, row.key) && row.bold,
  }
}

const formatDate = (dateObj) => {
  const { isEstimate } = dateObj
  const suffix = isEstimate ? "E" : "A"
  const date = isEstimate
    ? dateObj.fiscalDate
    : dateObj.formattedFiperiodenddate
  return `${date}${suffix}`
}

const getCellObj = (rowKey, dateKey) => {
  if (rowKey === "valuationEpsNormalized") {
    const newDateKey = getYearKey(dateKey, 1)
    return props.tableData?.[rowKey]?.[newDateKey]
  }
  return props.tableData?.[rowKey]?.[dateKey]
}

const getCellValue = (rowKey, dateKey) => {
  if (rowKey === "valuationEpsNormalized") {
    const newDateKey = getYearKey(dateKey, 1)
    return props.tableData?.[rowKey]?.[newDateKey]?.v
  }
  return props.tableData?.[rowKey]?.[dateKey]?.v
}

const isValidCell = (row, dateKey, mostRecentActualDateKeyStr) => {
  const rowKey = row.key
  const hideActuals = Boolean(row?.hideActuals)
  const cellValue = getCellValue(rowKey, dateKey)
  if (rowKey === "cumulativeDividendsPerShare") {
    return isFinite(cellValue) && cellValue !== 0
  }
  if (rowKey === "stockPrice") {
    const oneYearAhead = getYearKey(mostRecentActualDateKeyStr, 1)
    return isFinite(cellValue) && dateKey <= oneYearAhead
  }
  if (hideActuals) {
    return isFinite(cellValue) && dateKey > props.mostRecentActualDateKeyStr
  }
  return isFinite(cellValue)
}

return { __sfc: true,props, emit, I18nFn, handleValueChange, isFirstHighlightRow, findLastHighlightIndex, isLastHighlightRow, generateRowClasses, generateTitleCellClasses, generateCommonCellClasses, formatDate, getCellObj, getCellValue, isValidCell, UnauthorizedIcon, NumericInput, PeCell, getRowKey }
}

}