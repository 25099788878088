import "core-js/modules/web.timers.js";
import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'OverwriteActiveModelDialog',
  setup: function setup(__props) {
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive,
      I18nFn = _useBaseUtils.I18nFn;
    var updating = ref(false);
    var notification = ref(false);
    var open = computed(function () {
      return $store.state.valuationModel.dialogs.overwriteActiveModel;
    });
    var onClose = function onClose() {
      $store.dispatch("valuationModel/changeDialogState", {
        dialogId: "overwriteActiveModel",
        value: false
      });
    };
    var onChangeActiveModel = function onChangeActiveModel() {
      updating.value = true;
      $store.dispatch("valuationModel/changeActiveModel", {
        onAfterSuccessfulChange: function onAfterSuccessfulChange() {
          updating.value = false;
          $store.dispatch("valuationModel/fetchAllModels");
          notification.value = true;
          setTimeout(function () {
            notification.value = false;
          }, 4000);
          onClose();
        }
      });
      return false;
    };
    return {
      __sfc: true,
      $store: $store,
      darkActive: darkActive,
      I18nFn: I18nFn,
      updating: updating,
      notification: notification,
      open: open,
      onClose: onClose,
      onChangeActiveModel: onChangeActiveModel
    };
  }
};