import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import useBaseUtils from "~/functions/useBaseUtils";
import useCiqCharts from "~/functions/useCiqCharts";
import useCiqTableOptions from "~/functions/useCiqTableOptions";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
import useNumberFormatters from "~/functions/useNumberFormatters";
import dayjs from "~/utils/tools/dayjs";
import { roundUpto } from "~/utils/constants/numbers";
import { checkForInvalidEPS, createChartData, createCurrencyFormatter, createHistoricalTable, createNumberFormatter, createPercentFormatter, createStoreDataObject, createHistoricalTableFilteredData, createHistoricalYearsAcc, getWarningMessageByCompanyDescription, setDefaultRange, createForecastTable, getInitialDateStartingPoint, getInitEstimatesDataObj, getInitFinancialsDataObj, getPriceOverEarnings } from "~/utils/valuationTransform/peModel";
import { updateValuationOutput } from "~/utils/valuationTransform/index";
import { initialFilterState, createInitialFilterState, historicalRowsTemplate } from "~/utils/valuationTransform/peValuationTemplates";
import { getYearKey } from "~/utils/tools";
import { calculateCAGR } from "~/modules/ratios";
var useInitialPeValuationInputs = function useInitialPeValuationInputs(_ref) {
  var templates = _ref.templates;
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var _useBaseUtils = useBaseUtils(),
    dev = _useBaseUtils.dev,
    customGroup = _useBaseUtils.customGroup;
  var _useNumberFormatters = useNumberFormatters(),
    dynamicTwoDigitCurrencyFormatter = _useNumberFormatters.dynamicTwoDigitCurrencyFormatter;
  var _useCiqTableOptions = useCiqTableOptions({
      tableType: "estimates",
      storeModule: "ciq"
    }),
    currentIso = _useCiqTableOptions.currentIso;
  var filterState = createInitialFilterState(initialFilterState, templates.assumptionCases);
  var _useDateTimeFormatter = useDateTimeFormatters(),
    utcEpochToShortDate = _useDateTimeFormatter.utcEpochToShortDate,
    parseUtcDateTime = _useDateTimeFormatter.parseUtcDateTime;
  var filters = ref(_objectSpread({}, filterState));
  var resetCount = ref(0);
  var currentDataset = computed(function () {
    return $store.state.valuationModel.data.currentDataset;
  });
  var localInitialLoading = computed(function () {
    if ($store.state.valuationModel.currentModelId) {
      return $store.state.valuationModel.loading.initialLoad;
    }
    return loading.value;
  });
  var $newStoreDataObject = computed(function () {
    if (localInitialLoading.value) {
      return {};
    }
    if ($store.state.valuationModel.currentModelId) {
      return $store.state.valuationModel.data.storeDataObject;
    }
    var storeProps = {
      $store: $store,
      isDev: dev.value,
      currentDataset: $store.state.valuationModel.data.currentDataset,
      parseUtcDateTime: parseUtcDateTime,
      utcEpochToShortDate: utcEpochToShortDate.value,
      estimatesDataArr: templates.estimatesDataArray,
      valuationDataArr: templates.valuationDataArray,
      computedMetricsArr: templates.calculatedMetrics,
      currentIso: currentIso.value,
      requiredMetricKeys: templates.requiredMetrics
    };
    return createStoreDataObject(storeProps);
  });
  var actualDataset = computed(function () {
    return $store.state.valuationModel.data.currentDataset;
  });
  var loading = computed(function () {
    var loadingStore = $store.state.ciq.fetchingEstimates || $store.state.ciq.fetchingValuation || $store.state.ciq.fetchingFinancials;
    return loadingStore;
  });
  var formatNumber = computed(function () {
    return createNumberFormatter({
      minDigits: filters.value.decimals,
      maxDigits: filters.value.decimals,
      lang: navigator.language
    });
  });
  var oneDigitPercentFormatter = computed(function () {
    var lang = navigator.language || "default";
    return createPercentFormatter({
      minDigits: 1,
      maxDigits: 1,
      lang: lang
    });
  });
  var formatPercent = computed(function () {
    var lang = navigator.language || "default";
    return createPercentFormatter({
      minDigits: filters.value.decimals,
      maxDigits: filters.value.decimals,
      lang: lang
    });
  });
  var formatCurrency = computed(function () {
    return dynamicTwoDigitCurrencyFormatter(currentIso.value);
  });
  var historicalTable = computed(function () {
    var _$store$state$valuati;
    var currentDataset = $store.state.valuationModel.data.currentDataset;
    return createHistoricalTable({
      templateRows: historicalRowsTemplate,
      valuationOutput: valuationOutputDataObject.value,
      mostRecentActualDateKeyStr: $store.state.valuationModel.data.storeDataObject.mostRecentActualDateKeyStr,
      dateFormatter: utcEpochToShortDate.value,
      numberFormatter: formatNumber.value,
      percentFormatter: formatPercent.value,
      currencyFormatter: formatCurrency.value,
      fullfilledDates: (_$store$state$valuati = $store.state.valuationModel.data) === null || _$store$state$valuati === void 0 || (_$store$state$valuati = _$store$state$valuati.storeDataObject) === null || _$store$state$valuati === void 0 || (_$store$state$valuati = _$store$state$valuati.datasets) === null || _$store$state$valuati === void 0 || (_$store$state$valuati = _$store$state$valuati[currentDataset]) === null || _$store$state$valuati === void 0 ? void 0 : _$store$state$valuati.fullfilledDates,
      currentUserTier: customGroup.value
    });
  });
  var threeYearAheadForecastTable = computed(function () {
    var _initialStartEndpoint;
    var yearStart = initialStartEndpoint === null || initialStartEndpoint === void 0 || (_initialStartEndpoint = initialStartEndpoint.value) === null || _initialStartEndpoint === void 0 ? void 0 : _initialStartEndpoint.startYear;
    var yearEnd = yearStart + 3;
    return createForecastTable(templates.assumptionCases, assumptionsOutput.value, function () {
      return true;
    }, $store.state.valuationModel.data.storeDataObject, yearStart, yearEnd, {
      utcEpochToShortDate: utcEpochToShortDate.value,
      formatNumber: formatNumber.value,
      formatPercent: formatPercent.value,
      formatCurrency: formatCurrency.value
    });
  });
  var forecastTable = computed(function () {
    var _initialStartEndpoint2, _initialStartEndpoint3;
    var yearStart = (_initialStartEndpoint2 = initialStartEndpoint.value) === null || _initialStartEndpoint2 === void 0 ? void 0 : _initialStartEndpoint2.startYear;
    var yearEnd = (_initialStartEndpoint3 = initialStartEndpoint.value) === null || _initialStartEndpoint3 === void 0 ? void 0 : _initialStartEndpoint3.endYear;
    return createForecastTable(templates.assumptionCases, assumptionsOutput.value, function () {
      return true;
    }, $store.state.valuationModel.data.storeDataObject, yearStart, yearEnd, {
      utcEpochToShortDate: utcEpochToShortDate.value,
      formatNumber: formatNumber.value,
      formatPercent: formatPercent.value,
      formatCurrency: formatCurrency.value
    });
  });
  var hasInvalidEPS = computed(function () {
    return checkForInvalidEPS({
      valuationOutputDataObject: valuationOutputDataObject.value,
      $storeDataObject: $newStoreDataObject.value,
      currentDataset: currentDataset.value
    });
  });
  var companyWarningMessage = computed(function () {
    var ticker = $store.state.ciq.ticker;
    return getWarningMessageByCompanyDescription(ticker.simpleindustrydescription, ticker.tickersymbol, hasInvalidEPS.value);
  });
  var initVendorDataObj = computed(function () {
    var _$newStoreDataObject$;
    return (_$newStoreDataObject$ = $newStoreDataObject.value) === null || _$newStoreDataObject$ === void 0 || (_$newStoreDataObject$ = _$newStoreDataObject$.datasets) === null || _$newStoreDataObject$ === void 0 || (_$newStoreDataObject$ = _$newStoreDataObject$[actualDataset.value]) === null || _$newStoreDataObject$ === void 0 ? void 0 : _$newStoreDataObject$.initVendorDataObj;
  });
  var freshInitVendorDataObj = computed(function () {
    var _$valuationStore$data, _$ciqStore$financials;
    var currentDataset = actualDataset.value;
    var $ciqStore = $store.state.ciq;
    var $valuationStore = $store.state.valuationModel;
    var mostRecentActualDateKeyStr = $valuationStore === null || $valuationStore === void 0 || (_$valuationStore$data = $valuationStore.data) === null || _$valuationStore$data === void 0 || (_$valuationStore$data = _$valuationStore$data.storeDataObject) === null || _$valuationStore$data === void 0 ? void 0 : _$valuationStore$data.mostRecentActualDateKeyStr;
    var amountOfYearsForward = $valuationStore.data.amountOfYearsForward;
    var estimatesDataArray = $valuationStore.templates.estimatesDataArray;
    if (currentDataset === "estimates") {
      return getInitEstimatesDataObj($ciqStore.estimates, estimatesDataArray, dev.value, amountOfYearsForward);
    }
    return getInitFinancialsDataObj(($ciqStore === null || $ciqStore === void 0 || (_$ciqStore$financials = $ciqStore.financials) === null || _$ciqStore$financials === void 0 || (_$ciqStore$financials = _$ciqStore$financials.a) === null || _$ciqStore$financials === void 0 ? void 0 : _$ciqStore$financials.resData) || {}, mostRecentActualDateKeyStr, amountOfYearsForward, estimatesDataArray);
  });
  var userInputDataObjs = computed(function () {
    return $store.state.valuationModel.data.userInputsDataArray;
  });
  var initialUserInputDataObjs = computed(function () {
    return $store.state.valuationModel.data.initialUserInputsDataArray;
  });
  var initialAssumptionsOutput = computed(function () {
    if (localInitialLoading.value) {
      return [{}];
    }
    var cases = templates.assumptionCases.length > 0 ? templates.assumptionCases : [{}];
    return cases.map(function (_, idx) {
      var _$newStoreDataObject$2;
      return updateValuationOutput(initVendorDataObj.value, initialUserInputDataObjs.value[idx], templates.estimatesDataArray, (_$newStoreDataObject$2 = $newStoreDataObject.value) === null || _$newStoreDataObject$2 === void 0 || (_$newStoreDataObject$2 = _$newStoreDataObject$2.datasets) === null || _$newStoreDataObject$2 === void 0 || (_$newStoreDataObject$2 = _$newStoreDataObject$2[actualDataset.value]) === null || _$newStoreDataObject$2 === void 0 ? void 0 : _$newStoreDataObject$2.mostRecentActualDateKeyStr, templates.calculatedMetrics, templates.valuationDataArray, $newStoreDataObject.value.fullfilledDates);
    });
  });
  var assumptionsOutput = computed(function () {
    if (localInitialLoading.value) {
      return [{}];
    }
    var cases = templates.assumptionCases.length > 0 ? templates.assumptionCases : [{}];
    return cases.map(function (_, idx) {
      var _$newStoreDataObject$3;
      return updateValuationOutput(initVendorDataObj.value, userInputDataObjs.value[idx], templates.estimatesDataArray, (_$newStoreDataObject$3 = $newStoreDataObject.value) === null || _$newStoreDataObject$3 === void 0 || (_$newStoreDataObject$3 = _$newStoreDataObject$3.datasets) === null || _$newStoreDataObject$3 === void 0 || (_$newStoreDataObject$3 = _$newStoreDataObject$3[actualDataset.value]) === null || _$newStoreDataObject$3 === void 0 ? void 0 : _$newStoreDataObject$3.mostRecentActualDateKeyStr, templates.calculatedMetrics, templates.valuationDataArray, $newStoreDataObject.value.fullfilledDates);
    });
  });
  var valuationOutputDataObject = computed(function () {
    return assumptionsOutput.value[filters.value.assumptionCase];
  });
  var forecastedSummaryDataArr = computed(function () {
    // get x items from array starting from the end, except for the last one
    var initialRangeIdx = filters.value.dateRange[0] - 1;
    var finalRangeIdx = filters.value.dateRange[1] - 1;
    var filtered = allDates.value.filter(function (_, idx) {
      var isInRange = idx >= initialRangeIdx && idx <= finalRangeIdx;
      return isInRange;
    });
    if (filters.value.reverse) {
      return filtered.reverse();
    }
    return filtered;
  });
  var currencies = computed(function () {
    return $newStoreDataObject.value.datasets[actualDataset.value].currencies;
  });
  var formatCurrencyNoDecimals = computed(function () {
    var _currencies$value$fil;
    var lang = navigator.language || "default";
    return createCurrencyFormatter({
      minDigits: 0,
      maxDigits: 0,
      lang: lang,
      isocode: (_currencies$value$fil = currencies.value[filters.value.currency]) === null || _currencies$value$fil === void 0 ? void 0 : _currencies$value$fil.code
    });
  });
  var formatCurrencyReactive = computed(function () {
    var _currencies$value$fil2;
    var lang = navigator.language || "default";
    return createCurrencyFormatter({
      minDigits: filters.value.decimals,
      maxDigits: filters.value.decimals,
      lang: lang,
      isocode: (_currencies$value$fil2 = currencies.value[filters.value.currency]) === null || _currencies$value$fil2 === void 0 ? void 0 : _currencies$value$fil2.code
    });
  });
  var formatCurrencyTwoDecimals = computed(function () {
    var _currencies$value$fil3;
    var lang = navigator.language || "default";
    return createCurrencyFormatter({
      minDigits: 2,
      maxDigits: 2,
      lang: lang,
      isocode: (_currencies$value$fil3 = currencies.value[filters.value.currency]) === null || _currencies$value$fil3 === void 0 ? void 0 : _currencies$value$fil3.code
    });
  });
  var formatFloatTwoDigits = computed(function () {
    var lang = navigator.language || "default";
    return new Intl.NumberFormat(lang, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  });
  var createChartOffset = function createChartOffset(amountOfYearsForward) {
    // initial offset array, two years back
    var offset = [-1, 0]; // loop through the amount of years forward and add to the offset array
    for (var i = 1; i <= amountOfYearsForward; i++) {
      offset.push(i);
    }
    return offset;
  };
  var commonFormatters = computed(function () {
    return {
      "float": formatFloatTwoDigits.value,
      dateFormatter: utcEpochToShortDate.value
    };
  });
  var chartData = computed(function () {
    var _$newStoreDataObject$4; // one year backwards, three years forward
    var currentYear = $store.state.valuationModel.data.currentYear;
    var endYear = initialStartEndpoint.value.endYear;
    var chartYearOffset = createChartOffset(endYear - currentYear);
    return createChartData({
      offset: chartYearOffset,
      fullfilledDates: $newStoreDataObject === null || $newStoreDataObject === void 0 || (_$newStoreDataObject$4 = $newStoreDataObject.value) === null || _$newStoreDataObject$4 === void 0 ? void 0 : _$newStoreDataObject$4.fullfilledDates,
      mostRecentActualDateKeyStr: $newStoreDataObject.value.mostRecentActualDateKeyStr,
      assumptionCases: templates.assumptionCases,
      assumptionsOutput: assumptionsOutput.value,
      formatters: _objectSpread({
        formatCurrencyNoDecimals: formatCurrencyNoDecimals.value,
        formatCurrency: formatCurrencyTwoDecimals.value
      }, commonFormatters.value),
      amountOfYearsForward: $store.state.valuationModel.data.amountOfYearsForward
    });
  });
  var initialStartEndpoint = computed(function () {
    var _$store$state$valuati2;
    var currentDataset = $store.state.valuationModel.data.currentDataset;
    return getInitialDateStartingPoint({
      dates: (_$store$state$valuati2 = $store.state.valuationModel.data.storeDataObject.datasets) === null || _$store$state$valuati2 === void 0 || (_$store$state$valuati2 = _$store$state$valuati2[currentDataset]) === null || _$store$state$valuati2 === void 0 ? void 0 : _$store$state$valuati2.fullfilledDates,
      dateRange: filters.value.dateRange
    });
  });
  var currentModelId = computed(function () {
    return $store.state.valuationModel.currentModelId;
  });
  watch([loading, currentModelId], function (newState) {
    var _newState = _slicedToArray(newState, 2),
      loadingState = _newState[0],
      modelId = _newState[1];
    var isNewModel = !modelId;
    if (isNewModel && loadingState === false || modelId) {
      var _$newStoreDataObject$5;
      if (isNewModel) {
        $store.dispatch("valuationModel/resetUserInputs");
      }
      setDefaultRange({
        datesArr: (_$newStoreDataObject$5 = $newStoreDataObject.value) === null || _$newStoreDataObject$5 === void 0 || (_$newStoreDataObject$5 = _$newStoreDataObject$5.datasets) === null || _$newStoreDataObject$5 === void 0 || (_$newStoreDataObject$5 = _$newStoreDataObject$5[actualDataset.value]) === null || _$newStoreDataObject$5 === void 0 ? void 0 : _$newStoreDataObject$5.fullfilledDates,
        refToUpdate: filters
      });
    }
  }, {
    immediate: true
  });
  var currentDatasetObj = computed(function () {
    var _$newStoreDataObject$6;
    return (_$newStoreDataObject$6 = $newStoreDataObject.value) === null || _$newStoreDataObject$6 === void 0 || (_$newStoreDataObject$6 = _$newStoreDataObject$6.datasets) === null || _$newStoreDataObject$6 === void 0 ? void 0 : _$newStoreDataObject$6[actualDataset.value];
  });
  var allDates = computed(function () {
    var _currentDatasetObj$va;
    return (_currentDatasetObj$va = currentDatasetObj.value) === null || _currentDatasetObj$va === void 0 ? void 0 : _currentDatasetObj$va.fullfilledDates;
  });
  var isValidLastActuals = computed(function () {
    var _currentDatasetObj$va2;
    return (_currentDatasetObj$va2 = currentDatasetObj.value) === null || _currentDatasetObj$va2 === void 0 ? void 0 : _currentDatasetObj$va2.isValidLastActuals;
  });
  var recommendedDataset = computed(function () {
    var _$newStoreDataObject$7;
    return (_$newStoreDataObject$7 = $newStoreDataObject.value) === null || _$newStoreDataObject$7 === void 0 ? void 0 : _$newStoreDataObject$7.recommendedDataset;
  });
  var isFinancialDataSet = computed(function () {
    return actualDataset.value === "financials";
  });
  var rowLabels = computed(function () {
    var _currentDatasetObj$va3;
    return (_currentDatasetObj$va3 = currentDatasetObj.value) === null || _currentDatasetObj$va3 === void 0 ? void 0 : _currentDatasetObj$va3.labels;
  });
  var mostRecentActualDateKeyStr = computed(function () {
    var _currentDatasetObj$va4;
    return (_currentDatasetObj$va4 = currentDatasetObj.value) === null || _currentDatasetObj$va4 === void 0 ? void 0 : _currentDatasetObj$va4.mostRecentActualDateKeyStr;
  });
  var stockValues = computed(function () {
    return $newStoreDataObject.value.stockValues;
  });
  var historicalYearObjConfig = [{
    label: "1 Year"
  }, {
    label: "3 Year",
    useAsFallback: true
  }, {
    label: "5 Year",
    hideIfNotAvailable: true
  }, {
    label: "10 Year",
    hideIfNotAvailable: true
  }];
  var historicalYears = computed(function () {
    return createHistoricalYearsAcc({
      historicalYearObjConfig: historicalYearObjConfig,
      historicalTableData: historicalTable.value
    });
  });
  var historicalTableFilteredData = computed(function () {
    return createHistoricalTableFilteredData({
      historicalYears: historicalYears.value,
      historicalTableData: historicalTable.value
    });
  });
  var getAverage = function getAverage(_ref2) {
    var years = _ref2.years,
      baseKey = _ref2.baseKey,
      key = _ref2.key,
      assumptionObj = _ref2.assumptionObj;
    var absoluteAmountOfYears = Math.abs(years);
    var isNegative = years < 0; // create array of indexes to access
    var yearsArr = Array.from({
      length: absoluteAmountOfYears
    }, function (_, i) {
      return i;
    });
    if (isNegative) {
      yearsArr = yearsArr.map(function (i) {
        return -i;
      });
    }
    var valuesObjs = yearsArr.map(function (num) {
      var _assumptionObj$key; // get yearKey based on baseKey and num
      var yearKey = getYearKey(baseKey, num);
      var metricObj = (assumptionObj === null || assumptionObj === void 0 || (_assumptionObj$key = assumptionObj[key]) === null || _assumptionObj$key === void 0 ? void 0 : _assumptionObj$key[yearKey]) || {};
      return metricObj;
    });
    var valuesArr = yearsArr.map(function (num) {
      var _assumptionObj$key2; // get yearKey based on baseKey and num
      var yearKey = getYearKey(baseKey, num);
      var metricObj = (assumptionObj === null || assumptionObj === void 0 || (_assumptionObj$key2 = assumptionObj[key]) === null || _assumptionObj$key2 === void 0 ? void 0 : _assumptionObj$key2[yearKey]) || {};
      return metricObj === null || metricObj === void 0 ? void 0 : metricObj.v;
    });
    var sum = valuesArr.reduce(function (acc, curr) {
      return acc + curr;
    }, 0);
    var unauth = valuesObjs.some(function (v) {
      return v === null || v === void 0 ? void 0 : v.unauth;
    });
    var v = roundUpto(sum / absoluteAmountOfYears * 100, 1);
    return {
      v: v,
      unauth: unauth,
      label: "".concat(absoluteAmountOfYears, " Year Average (").concat(valuesArr.map(function (v) {
        return roundUpto(v * 100, 2);
      }).join(", "), ")")
    };
  };
  var getCagrObj = function getCagrObj(_ref3) {
    var from = _ref3.from,
      to = _ref3.to,
      key = _ref3.key,
      assumptionObj = _ref3.assumptionObj,
      formatters = _ref3.formatters;
    var metricObj = (assumptionObj === null || assumptionObj === void 0 ? void 0 : assumptionObj[key]) || {};
    var fromObj = metricObj[from] || {};
    var toObj = metricObj[to] || {};
    var initialValue = fromObj === null || fromObj === void 0 ? void 0 : fromObj.v;
    var firstDate = (fromObj === null || fromObj === void 0 ? void 0 : fromObj.timeVal) || (fromObj === null || fromObj === void 0 ? void 0 : fromObj.dV);
    var lastValue = toObj === null || toObj === void 0 ? void 0 : toObj.v;
    var lastDate = (toObj === null || toObj === void 0 ? void 0 : toObj.timeVal) || (toObj === null || toObj === void 0 ? void 0 : toObj.dV);
    var isValid = initialValue && lastValue && firstDate && lastDate;
    if (isValid) {
      var _calculateCAGR = calculateCAGR({
          initialValue: initialValue,
          lastValue: lastValue,
          firstDate: firstDate,
          lastDate: lastDate
        }),
        cagr = _calculateCAGR.cagr;
      var value = cagr * 100;
      var formattedInitialDate = dayjs(firstDate).format("MM/DD/YYYY");
      var formattedFinalDate = dayjs(lastDate).format("MM/DD/YYYY");
      if (formatters !== null && formatters !== void 0 && formatters.dateFormatter) {
        formattedInitialDate = formatters.dateFormatter.format(firstDate);
        formattedFinalDate = formatters.dateFormatter.format(lastDate);
      }
      var formattedInitialValue = formatters !== null && formatters !== void 0 && formatters["float"] ? formatters["float"].format(initialValue) : initialValue;
      var formattedLastValue = formatters !== null && formatters !== void 0 && formatters["float"] ? formatters["float"].format(lastValue) : lastValue;
      return {
        label: "From: ".concat(formattedInitialValue, " (").concat(formattedInitialDate, ") To: ").concat(formattedLastValue, " (").concat(formattedFinalDate, ")"),
        unauth: (fromObj === null || fromObj === void 0 ? void 0 : fromObj.unauth) || (toObj === null || toObj === void 0 ? void 0 : toObj.unauth),
        v: roundUpto(value, 2)
      };
    }
    return {
      label: "",
      v: null
    };
  };
  var getCagrFromKey = function getCagrFromKey(_ref4) {
    var from = _ref4.from,
      to = _ref4.to,
      key = _ref4.key,
      assumptionObj = _ref4.assumptionObj,
      _ref4$number = _ref4.number,
      number = _ref4$number === void 0 ? false : _ref4$number;
    var metricObj = (assumptionObj === null || assumptionObj === void 0 ? void 0 : assumptionObj[key]) || {};
    var fromObj = metricObj[from] || {};
    var toObj = metricObj[to] || {};
    var initialValue = fromObj === null || fromObj === void 0 ? void 0 : fromObj.v;
    var firstDate = (fromObj === null || fromObj === void 0 ? void 0 : fromObj.timeVal) || (fromObj === null || fromObj === void 0 ? void 0 : fromObj.dV);
    var lastValue = toObj === null || toObj === void 0 ? void 0 : toObj.v;
    var lastDate = (toObj === null || toObj === void 0 ? void 0 : toObj.timeVal) || (toObj === null || toObj === void 0 ? void 0 : toObj.dV);
    var isValid = initialValue && lastValue && firstDate && lastDate;
    if (isValid) {
      var _calculateCAGR2 = calculateCAGR({
          initialValue: initialValue,
          lastValue: lastValue,
          firstDate: firstDate,
          lastDate: lastDate
        }),
        cagr = _calculateCAGR2.cagr;
      var value = cagr * 100;
      if (number) {
        return value;
      }
      return value.toFixed(1);
    }
    return null;
  };
  var createStepChartDataArr = function createStepChartDataArr(_ref5) {
    var items = _ref5.items,
      assumptionObj = _ref5.assumptionObj,
      mostRecentActualDateKeyStr = _ref5.mostRecentActualDateKeyStr;
    return items.map(function (metricObj) {
      var metricKey = metricObj.metric;
      var multiplier = (metricObj === null || metricObj === void 0 ? void 0 : metricObj.multiplier) || 1;
      var metricDataObj = (assumptionObj === null || assumptionObj === void 0 ? void 0 : assumptionObj[metricKey]) || {};
      var keys = Object.keys(metricDataObj).filter(function (key) {
        return key <= mostRecentActualDateKeyStr;
      });
      return {
        metric: metricKey,
        values: keys.map(function (key) {
          var metricYearObj = (metricDataObj === null || metricDataObj === void 0 ? void 0 : metricDataObj[key]) || {};
          return _objectSpread(_objectSpread({}, metricYearObj), {}, {
            v: (metricYearObj === null || metricYearObj === void 0 ? void 0 : metricYearObj.v) * multiplier
          });
        })
      };
    });
  };
  var simpleModelProps = computed(function () {
    try {
      var _$store$state, _assumptionsOutput$va, _initialAssumptionsOu, _initialAssumptionsOu2, _allDates$value, _allDates$value2, _defaultAssumptionDat3, _defaultAssumptionOri, _defaultAssumptionOri2, _defaultAssumptionDat4, _defaultAssumptionDat5, _defaultAssumptionDat6, _defaultAssumptionDat7, _threeYearAheadForeca, _epsDataObj$data, _threeYearAheadForeca2, _defaultAssumptionDat8, _defaultAssumptionDat9, _peMultipleDataObj$da, _defaultAssumptionDat10, _defaultAssumptionDat11;
      var templatesArr = ((_$store$state = $store.state) === null || _$store$state === void 0 || (_$store$state = _$store$state.valuationModel) === null || _$store$state === void 0 || (_$store$state = _$store$state.templates) === null || _$store$state === void 0 ? void 0 : _$store$state.assumptionCases) || [];
      var defaultTemplateIdx = templatesArr.findIndex(function (i) {
        return i.id === "mid";
      });
      var defaultAssumptionDataObj = (_assumptionsOutput$va = assumptionsOutput.value) === null || _assumptionsOutput$va === void 0 ? void 0 : _assumptionsOutput$va[defaultTemplateIdx];
      var initialAssumptionDataObj = (_initialAssumptionsOu = initialAssumptionsOutput.value) === null || _initialAssumptionsOu === void 0 ? void 0 : _initialAssumptionsOu[defaultTemplateIdx];
      var defaultAssumptionOriginalDataObj = (_initialAssumptionsOu2 = initialAssumptionsOutput.value) === null || _initialAssumptionsOu2 === void 0 ? void 0 : _initialAssumptionsOu2[defaultTemplateIdx];
      var lastActualKey = mostRecentActualDateKeyStr.value;
      var threeYearsFwdKey = getYearKey(lastActualKey, 3);
      var threeYearsBackKey = getYearKey(lastActualKey, -3);
      var threeYearsAheadDateObj = (_allDates$value = allDates.value) === null || _allDates$value === void 0 ? void 0 : _allDates$value.find(function (i) {
        return i.dateKey === threeYearsFwdKey;
      });
      var peMultiples = getPriceOverEarnings($store);
      var threeYearsFwdYear = threeYearsAheadDateObj === null || threeYearsAheadDateObj === void 0 ? void 0 : threeYearsAheadDateObj.fiscalYear;
      var filteredDates = (_allDates$value2 = allDates.value) === null || _allDates$value2 === void 0 ? void 0 : _allDates$value2.filter(function (dateObj) {
        return dateObj.dateKey >= threeYearsBackKey && dateObj.dateKey <= threeYearsFwdKey;
      });
      var filteredDateLabels = filteredDates.map(function (dateObj) {
        if (dateObj !== null && dateObj !== void 0 && dateObj.isEstimate) {
          return "".concat(dateObj === null || dateObj === void 0 ? void 0 : dateObj.fiscalYear, " E");
        }
        return String(dateObj === null || dateObj === void 0 ? void 0 : dateObj.fiscalYear);
      });
      var chartDataPoints = filteredDates.map(function (dateObj) {
        var _defaultAssumptionDat2;
        var dateKey = dateObj === null || dateObj === void 0 ? void 0 : dateObj.dateKey;
        if (dateObj !== null && dateObj !== void 0 && dateObj.isEstimate) {
          var _defaultAssumptionDat;
          var _metricObj = defaultAssumptionDataObj === null || defaultAssumptionDataObj === void 0 || (_defaultAssumptionDat = defaultAssumptionDataObj.totalStockPriceIncludingDividends) === null || _defaultAssumptionDat === void 0 ? void 0 : _defaultAssumptionDat[dateKey];
          var _timestamp = _metricObj === null || _metricObj === void 0 ? void 0 : _metricObj.dV;
          var _value = _metricObj === null || _metricObj === void 0 ? void 0 : _metricObj.v;
          return [_timestamp, _value];
        }
        var metricObj = defaultAssumptionDataObj === null || defaultAssumptionDataObj === void 0 || (_defaultAssumptionDat2 = defaultAssumptionDataObj.stockPrice) === null || _defaultAssumptionDat2 === void 0 ? void 0 : _defaultAssumptionDat2[dateKey];
        var timestamp = metricObj === null || metricObj === void 0 ? void 0 : metricObj.dV;
        var value = metricObj === null || metricObj === void 0 ? void 0 : metricObj.v;
        return [timestamp, value];
      });
      var latestStockPrice = defaultAssumptionDataObj === null || defaultAssumptionDataObj === void 0 || (_defaultAssumptionDat3 = defaultAssumptionDataObj.stockPrice) === null || _defaultAssumptionDat3 === void 0 ? void 0 : _defaultAssumptionDat3.latest;
      var realizedAtDate = threeYearsAheadDateObj === null || threeYearsAheadDateObj === void 0 ? void 0 : threeYearsAheadDateObj.text;
      var revenueThreeYearFwdCagr = getCagrFromKey({
        from: lastActualKey,
        to: threeYearsFwdKey,
        key: "revenue",
        assumptionObj: initialAssumptionDataObj,
        number: true
      });
      var ebitMarginThreeYearsFwdObj = defaultAssumptionOriginalDataObj === null || defaultAssumptionOriginalDataObj === void 0 || (_defaultAssumptionOri = defaultAssumptionOriginalDataObj.ebitMargin) === null || _defaultAssumptionOri === void 0 ? void 0 : _defaultAssumptionOri[threeYearsFwdKey];
      var ebitMarginThreeYearsFwd = (ebitMarginThreeYearsFwdObj === null || ebitMarginThreeYearsFwdObj === void 0 ? void 0 : ebitMarginThreeYearsFwdObj.v) * 100;
      var peMultipleThreeYearsFwdObj = defaultAssumptionOriginalDataObj === null || defaultAssumptionOriginalDataObj === void 0 || (_defaultAssumptionOri2 = defaultAssumptionOriginalDataObj.priceOverEarningsMultiple) === null || _defaultAssumptionOri2 === void 0 ? void 0 : _defaultAssumptionOri2[threeYearsFwdKey];
      var irrThreeYearsFwdObj = defaultAssumptionDataObj === null || defaultAssumptionDataObj === void 0 || (_defaultAssumptionDat4 = defaultAssumptionDataObj.annualizedReturn) === null || _defaultAssumptionDat4 === void 0 ? void 0 : _defaultAssumptionDat4[threeYearsFwdKey];
      var peMultipleThreeYearsFwd = peMultipleThreeYearsFwdObj === null || peMultipleThreeYearsFwdObj === void 0 ? void 0 : peMultipleThreeYearsFwdObj.v;
      var consensus = {
        revenueThreeYearFwdCagr: revenueThreeYearFwdCagr,
        ebitMarginThreeYearsFwd: ebitMarginThreeYearsFwd,
        peMultipleThreeYearsFwd: peMultipleThreeYearsFwd
      };
      var stepsChartDataArr = createStepChartDataArr({
        items: [{
          metric: "revenue"
        }, {
          metric: "ebitMargin",
          multiplier: 100
        }, {
          metric: "priceOverEarningsMultiple"
        }],
        assumptionObj: defaultAssumptionDataObj,
        mostRecentActualDateKeyStr: mostRecentActualDateKeyStr.value
      });
      var _useCiqCharts = useCiqCharts({
          metrics: [{
            source: "financials",
            metrics: [{
              name: "Total Revenues"
            }, {
              name: "% Change YoY",
              dataitemid: ["28"]
            }]
          }, {
            source: "estimates",
            metrics: [{
              name: "% EBIT Margins"
            }]
          }, {
            source: "valuation",
            metrics: [{
              name: "NTM Price / Normalized Earnings (P/E)"
            }]
          }]
        }),
        items = _useCiqCharts.items;
      console.log({
        items: items.value
      });
      var revenueOneYearCagrObj = getCagrObj({
        from: getYearKey(lastActualKey, -1),
        to: lastActualKey,
        key: "revenue",
        assumptionObj: defaultAssumptionDataObj,
        formatters: commonFormatters.value
      });
      var revenueThreeYearCagr = getCagrObj({
        from: getYearKey(lastActualKey, -3),
        to: lastActualKey,
        key: "revenue",
        assumptionObj: defaultAssumptionDataObj,
        formatters: commonFormatters.value
      });
      var revenueFiveYearCagr = getCagrObj({
        from: getYearKey(lastActualKey, -5),
        to: lastActualKey,
        key: "revenue",
        assumptionObj: defaultAssumptionDataObj,
        formatters: commonFormatters.value
      });
      var revenueTenYearCagr = getCagrObj({
        from: getYearKey(lastActualKey, -10),
        to: lastActualKey,
        key: "revenue",
        assumptionObj: defaultAssumptionDataObj,
        formatters: commonFormatters.value
      });
      var ebitMarginLtm = ((_defaultAssumptionDat5 = defaultAssumptionDataObj.ebitMargin) === null || _defaultAssumptionDat5 === void 0 || (_defaultAssumptionDat5 = _defaultAssumptionDat5[lastActualKey]) === null || _defaultAssumptionDat5 === void 0 ? void 0 : _defaultAssumptionDat5.v) * 100;
      var ebitLastYear = _objectSpread(_objectSpread({}, (_defaultAssumptionDat6 = defaultAssumptionDataObj.ebitMargin) === null || _defaultAssumptionDat6 === void 0 ? void 0 : _defaultAssumptionDat6[lastActualKey]), {}, {
        v: roundUpto(((_defaultAssumptionDat7 = defaultAssumptionDataObj.ebitMargin) === null || _defaultAssumptionDat7 === void 0 || (_defaultAssumptionDat7 = _defaultAssumptionDat7[lastActualKey]) === null || _defaultAssumptionDat7 === void 0 ? void 0 : _defaultAssumptionDat7.v) * 100, 2)
      });
      var ebitLastThreeYears = getAverage({
        years: -3,
        baseKey: lastActualKey,
        key: "ebitMargin",
        assumptionObj: defaultAssumptionDataObj
      });
      var ebitLastFiveYears = getAverage({
        years: -5,
        baseKey: lastActualKey,
        key: "ebitMargin",
        assumptionObj: defaultAssumptionDataObj
      });
      var ebitLastTenYears = getAverage({
        years: -10,
        baseKey: lastActualKey,
        key: "ebitMargin",
        assumptionObj: defaultAssumptionDataObj
      });
      var returns = {
        revenues: {
          pastYear: revenueOneYearCagrObj,
          lastThreeYears: revenueThreeYearCagr,
          lastFiveYears: revenueFiveYearCagr,
          lastTenYears: revenueTenYearCagr
        },
        ebit: {
          ltm: ebitMarginLtm.toFixed(1),
          pastYear: ebitLastYear,
          lastThreeYears: ebitLastThreeYears,
          lastFiveYears: ebitLastFiveYears,
          lastTenYears: ebitLastTenYears
        },
        peMultiple: {
          latest: peMultiples.latest,
          lastYearAvg: peMultiples.lastYearAvg,
          lastThreeYears: peMultiples === null || peMultiples === void 0 ? void 0 : peMultiples.threeYearAverage,
          lastFiveYears: peMultiples === null || peMultiples === void 0 ? void 0 : peMultiples.fiveYearAverage,
          lastTenYears: peMultiples === null || peMultiples === void 0 ? void 0 : peMultiples.tenYearAverage
        }
      };
      var _chartData = {
        dateLabels: filteredDateLabels,
        dataPoints: chartDataPoints,
        currentStockPrice: latestStockPrice,
        realizedAtDate: threeYearsAheadDateObj
      };
      var epsDataObj = (_threeYearAheadForeca = threeYearAheadForecastTable.value) === null || _threeYearAheadForeca === void 0 || (_threeYearAheadForeca = _threeYearAheadForeca.tableData) === null || _threeYearAheadForeca === void 0 ? void 0 : _threeYearAheadForeca.find(function (obj) {
        return obj.label === "EPS Growth (CAGR)";
      });
      var defaultAssumptionDataIndex = 1;
      var threeYearAheadEpsFromDefaultAssumption = epsDataObj === null || epsDataObj === void 0 || (_epsDataObj$data = epsDataObj.data) === null || _epsDataObj$data === void 0 ? void 0 : _epsDataObj$data[defaultAssumptionDataIndex];
      var peMultipleDataObj = (_threeYearAheadForeca2 = threeYearAheadForecastTable.value) === null || _threeYearAheadForeca2 === void 0 || (_threeYearAheadForeca2 = _threeYearAheadForeca2.tableData) === null || _threeYearAheadForeca2 === void 0 ? void 0 : _threeYearAheadForeca2.find(function (obj) {
        return obj.label === "P/E Change";
      });
      var currentStockPriceObj = defaultAssumptionDataObj === null || defaultAssumptionDataObj === void 0 || (_defaultAssumptionDat8 = defaultAssumptionDataObj.stockPrice) === null || _defaultAssumptionDat8 === void 0 ? void 0 : _defaultAssumptionDat8.latest;
      var oneYearFwdDateKey = getYearKey(lastActualKey, 1);
      var firstDividendPerShareAhead = defaultAssumptionDataObj === null || defaultAssumptionDataObj === void 0 || (_defaultAssumptionDat9 = defaultAssumptionDataObj.dividendsPerShare) === null || _defaultAssumptionDat9 === void 0 ? void 0 : _defaultAssumptionDat9[oneYearFwdDateKey];
      var dividendYield = {
        v: (firstDividendPerShareAhead === null || firstDividendPerShareAhead === void 0 ? void 0 : firstDividendPerShareAhead.v) / (currentStockPriceObj === null || currentStockPriceObj === void 0 ? void 0 : currentStockPriceObj.v),
        label: "".concat(oneYearFwdDateKey, " Dividend: ").concat(firstDividendPerShareAhead === null || firstDividendPerShareAhead === void 0 ? void 0 : firstDividendPerShareAhead.v, " | Current Stock Price: ").concat(currentStockPriceObj === null || currentStockPriceObj === void 0 ? void 0 : currentStockPriceObj.v)
      };
      var threeYearAheadPeMultipleFromDefaultAssumption = peMultipleDataObj === null || peMultipleDataObj === void 0 || (_peMultipleDataObj$da = peMultipleDataObj.data) === null || _peMultipleDataObj$da === void 0 ? void 0 : _peMultipleDataObj$da[defaultAssumptionDataIndex];
      var totalStockPriceIncludingDividendsThreeYearsFwd = defaultAssumptionDataObj === null || defaultAssumptionDataObj === void 0 || (_defaultAssumptionDat10 = defaultAssumptionDataObj.totalStockPriceIncludingDividends) === null || _defaultAssumptionDat10 === void 0 ? void 0 : _defaultAssumptionDat10[threeYearsFwdKey];
      var totalReturnThreeYearsFwd = defaultAssumptionDataObj === null || defaultAssumptionDataObj === void 0 || (_defaultAssumptionDat11 = defaultAssumptionDataObj.upsideOrDownsideToCurrentStockPrice) === null || _defaultAssumptionDat11 === void 0 ? void 0 : _defaultAssumptionDat11[threeYearsFwdKey];
      var threeYearsFwdIrr = _objectSpread(_objectSpread({}, irrThreeYearsFwdObj), {}, {
        v: (irrThreeYearsFwdObj === null || irrThreeYearsFwdObj === void 0 ? void 0 : irrThreeYearsFwdObj.v) * 100
      });
      return {
        realizedAtDate: realizedAtDate,
        consensus: consensus,
        returns: returns,
        chartData: _chartData,
        newChartData: items.value,
        threeYearsFwdYear: threeYearsFwdYear,
        threeYearAheadEpsFromDefaultAssumption: threeYearAheadEpsFromDefaultAssumption,
        threeYearAheadPeMultipleFromDefaultAssumption: threeYearAheadPeMultipleFromDefaultAssumption,
        dividendYield: dividendYield,
        stepsChartDataArr: stepsChartDataArr,
        irr: threeYearsFwdIrr,
        totalReturn: totalReturnThreeYearsFwd,
        totalStockPrice: totalStockPriceIncludingDividendsThreeYearsFwd
      };
    } catch (error) {
      return {
        error: (error === null || error === void 0 ? void 0 : error.message) || error
      };
    }
  });
  var getMostRecentKey = function getMostRecentKey(dataset, $store) {
    var _$store$state2;
    return $store === null || $store === void 0 || (_$store$state2 = $store.state) === null || _$store$state2 === void 0 || (_$store$state2 = _$store$state2.ciq) === null || _$store$state2 === void 0 || (_$store$state2 = _$store$state2[dataset]) === null || _$store$state2 === void 0 || (_$store$state2 = _$store$state2.a) === null || _$store$state2 === void 0 ? void 0 : _$store$state2.mostRecentDateKey;
  };
  var isOutOfDate = computed(function () {
    var newKey = getMostRecentKey(actualDataset.value, $store);
    var modelKey = mostRecentActualDateKeyStr.value;
    if (!newKey || !modelKey) {
      return false;
    }
    return newKey !== modelKey;
  });
  return {
    allDates: allDates,
    loading: loading,
    initVendorDataObj: initVendorDataObj,
    isFinancialDataSet: isFinancialDataSet,
    isOutOfDate: isOutOfDate,
    freshInitVendorDataObj: freshInitVendorDataObj,
    isModelDisabled: $newStoreDataObject.value.isModelDisabled,
    mostRecentActualDateKeyStr: mostRecentActualDateKeyStr,
    userInputDataObjs: userInputDataObjs,
    currencies: currencies,
    valuationOutputDataObject: valuationOutputDataObject,
    hasInvalidEPS: hasInvalidEPS,
    companyWarningMessage: companyWarningMessage,
    stockValues: stockValues,
    filters: filters,
    resetCount: resetCount,
    setDefaultRange: setDefaultRange,
    forecastTable: forecastTable,
    utcEpochToShortDate: utcEpochToShortDate,
    chartData: chartData,
    recommendedDataset: recommendedDataset,
    currentDataset: currentDataset,
    isValidLastActuals: isValidLastActuals,
    actualDataset: actualDataset,
    oneDigitPercentFormatter: oneDigitPercentFormatter,
    formatPercent: formatPercent,
    formatNumber: formatNumber,
    formatCurrencyTwoDecimals: formatCurrencyTwoDecimals,
    formatCurrencyReactive: formatCurrencyReactive,
    rowLabels: rowLabels,
    forecastedSummaryDataArr: forecastedSummaryDataArr,
    historicalYears: historicalYears,
    historicalTableFilteredData: historicalTableFilteredData,
    simpleModelProps: simpleModelProps
  };
};
export default useInitialPeValuationInputs;